import React, { useEffect, useState } from "react";
import HeaderSearch from "../../shared/HeaderSearch";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  createTheme,
  TextField
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { DateCalendar, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockClockIcon from "@mui/icons-material/LockClock";
import innovationImage from "../../../Assets/Innovation.svg";
import { DatabaseService } from "../../../Services/DatabaseService";
import DashboardCalender from "../../../Assets/DashboardCalender.svg";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import FeedBackForm from "../../shared/FeedBackForm";
import { GeoAPIkey } from "../../../Constants/keys";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Search } from "@mui/icons-material";

const theme = createTheme();

function DashboardBody(props) {
  let { checkInData = {} } = props;
  const todayDate = dayjs();
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled,  } =
  useGeolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    watchLocationPermissionChange: true,
  });

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [today, setToday] = useState(dayjs(Date.now()));
  const [checkData, setCheckData] = useState({});
  const [attendanceLoading, setAttendanceLoading] = useState(true);
  const [attendanceData, setAttendanceData] = useState([]);
  const [leavesBalances, setLeavesBalances] = useState({available: 0, booked: 0});
  const [currentTime, setCurrentTime] = useState(dayjs(Date.now()));
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [finalAddress, setFinalAddress] = useState("");
  const [checkOutLimitTime, SetCheckOutLimitTime] = useState('');
  const [earlyCheckOutReason, setEarlyCheckOutReason] = useState("");
  const [openCheckOutDialog, setOpenCheckOutDialog] = useState(false);
  const [checkOutLoading, setCheckOutLoading] = useState(false);
  const [earlyCheckOutReasonError, setEarlyCheckOutReasonError] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (checkInData) {
      setCheckData(checkInData);
      getUserAttendance();
      getLookupCode();
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(dayjs(Date.now()));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(()=>{
    if(coords){
      // getAddressFromCoords()
      check_distance();
    }
  },[coords]);

  function haversine(lat1, lon1, lat2, lon2) {
    // Convert latitude and longitude from degrees to radians
    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);
  
    // Calculate differences
    var dlat = lat2 - lat1;
    var dlon = lon2 - lon1;
  
    // Haversine formula
    var a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    // Radius of Earth (mean radius in kilometers)
    var R = 6371.0;
  
    // Calculate distance
    var distance = R * c * 1000 ;
  
    return distance;
  }
  
  // Helper function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
  
  const check_distance = async () => {
    
    let {latitude = "", longitude = ""} = coords;

    // Favcy Venture Builders office location
    var lat1 = 28.553867914824547;
    var lon1 = 77.23135631400594;
    
    // inby spaces by Favcy office location
    var lat2 = 28.568028559596325;
    var lon2 = 77.2193460894781;
    var distance1 = haversine(lat1, lon1, latitude, longitude);
    var distance2 = haversine(lat2, lon2, latitude, longitude);
    console.log("Distance: " + distance1.toFixed(2) + " m");
    console.log("Distance2: " + distance2.toFixed(2) + " m");

    if(distance1.toFixed(2) < 20 || distance2.toFixed(2) < 20){
      console.log("less then 10 meters");
      setFinalAddress("In-Office");
    }else{
      console.log("greater then 10 meters");
      setFinalAddress("Out-of-Office");
    }
    
  }

  const getAddressFromCoords = async () => {
    let {latitude = "", longitude = ""} = coords;
    console.log("latitude-------------------->",latitude,"longitude----------------------->",longitude);
    // let location = await fetch(
    //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true/false&key=${GeoAPIkey}`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setAddress(data);
    //     getFinalAddress(data);
    //     console.log(data);
    //   })
    //   .catch((error) => console.log(error));
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const getUserAttendance = async () => {
    let endpoint = "attendance/single-user";
    if(fromDate && toDate){
      endpoint = endpoint + "?from_date="+ dayjs(fromDate).add(1,"day").format("YYYY-MM-DD") +"&to_date="+dayjs(toDate).add(1,"day").format("YYYY-MM-DD")
    }
    let attendResponse = await DatabaseService("GET", endpoint);
    if (attendResponse.code === 200) {
      setAttendanceData(attendResponse.data);
      if(attendResponse.leave){
        setLeavesBalances(attendResponse.leave);
        localStorage.setItem("leavesUpdates", JSON.stringify(attendResponse.leave));
      }
      setAttendanceLoading(false);
    }
  };

  const getFinalAddress = async (address) => {
    let routeAddress = await address?.results?.find((address) =>
      address.types.includes("route")
    );

    let premiseAddress = await address?.results?.find((address) =>
      address.types.includes("premise")
    );

    if (!routeAddress) {
      routeAddress = await address?.results[1];
    }

    if (!premiseAddress) {
      premiseAddress = await address?.results[0];
    }

    let finalRoute =
      (await routeAddress?.formatted_address?.split(",")[0].includes("+")) ||
      routeAddress?.formatted_address?.split(",")[0].includes("Unnamed Road")
        ? routeAddress?.formatted_address?.split(",")[1]
        : routeAddress?.formatted_address?.split(",")[0];

    let finalPremise =
      (await address?.results[0]?.formatted_address
        ?.split(",")[0]
        .includes("+")) ||
      address?.results[0]?.formatted_address
        ?.split(",")[0]
        .includes("Unnamed Road")
        ? address?.results[0]?.formatted_address?.split(",")[1]
        : address?.results[0]?.formatted_address?.split(",")[0];

    let finalFirstIndex =
      (await address?.results[1]?.formatted_address
        ?.split(",")[0]
        .includes("+")) ||
      address?.results[1]?.formatted_address
        ?.split(",")[0]
        .includes("Unnamed Road")
        ? address?.results[1]?.formatted_address?.split(",")[1]
        : address?.results[1]?.formatted_address?.split(",")[0];

    if (routeAddress?.formatted_address === premiseAddress?.formatted_address) {
      let finalAdd = (await finalFirstIndex) + ", " + finalRoute;
      setFinalAddress(finalAdd);
    } else {
      let finalAdd = (await finalPremise) + ", " + finalRoute;
      setFinalAddress(finalAdd);
    }
  };

  
  const getLookupCode = async () => {
    setAttendanceLoading(true);
    let lookupCodeRes = await DatabaseService("GET", "lookup/code_without_auth");
    console.log("lookup_response---------------------->", lookupCodeRes);
    if (lookupCodeRes.code === 200) {
      let responseData = lookupCodeRes.data;
      if(responseData. length){
        let filterCheckOut = responseData.find((lookupCode, index)=>{ return lookupCode.lookup_code_name.toLowerCase().includes("check out time") });
        if(filterCheckOut ){
          console.log("checkout_limit_time---------->",filterCheckOut.lookup_code_description);
          SetCheckOutLimitTime(filterCheckOut.lookup_code_description);
        }
      }
      setAttendanceLoading(false);
      
    } else {
      setAttendanceLoading(false);
    }
  };

  const OnCheckOutClick = () => {
    if (window.confirm("Are you sure you want to check out?")) {
      // Save it!

      if(checkOutLimitTime){
        // Parse the time strings into Day.js objects
        const parsedTime1 = dayjs(checkOutLimitTime, 'HH:mm');
        const parsedTime2 = dayjs(dayjs(currentTime.$d).format('HH:mm'), 'HH:mm');

        // Compare the parsed time objects
        if (parsedTime1.isAfter(parsedTime2)) {
            console.log(`${checkOutLimitTime} is greater than ${dayjs(currentTime.$d).format('HH:mm')}`);
            setOpenCheckOutDialog(true);
            setEarlyCheckOutReason("");
            setCheckOutLoading(false);
            return
        } else if (parsedTime1.isBefore(parsedTime2) ) {
            console.log(`${checkOutLimitTime} is less than ${dayjs(currentTime.$d).format('HH:mm')}`);
            handleCheckOut();
        } else {
            handleCheckOut();
            console.log(`${checkOutLimitTime} is equal to ${dayjs(currentTime.$d).format('HH:mm')}`);
        }


        handleCheckOut();

      }else{
        handleCheckOut();
      }

    } else {
      // Do nothing!
      return;
    }
  };

  const handleCheckOut = async () => {
    setCheckOutLoading(true);
    if (!coords) {
      alert("Your location services are not enabled for this Application. Please enable them to proceed further.");
      getPosition();
      setCheckOutLoading(false);
      return;
    }

    let checkOutData = {
      check_out_address: finalAddress,
      check_out_date: new Date(currentTime.$d),
      check_out_lat: coords.latitude,
      check_out_lng: coords.longitude,
      early_check_out_reason : earlyCheckOutReason.toString().trim()
    };

    let checkOutResponse = await DatabaseService(
      "POST",
      "attendance",
      checkOutData
    );
    console.log("checkOutdata---------------------->", checkOutResponse);
    if (checkOutResponse.code === 200) {
      setCheckOutLoading(false);
      setOpenCheckOutDialog(false);
      navigate("/");
    } else {
      setCheckOutLoading(false);
    }
  };

  const validateCheckoutReason = async () => {
    if(earlyCheckOutReason.toString().trim() === ""){
      setEarlyCheckOutReasonError(true);
    }else{
      handleCheckOut()
    }
  }

  
  const onfrom_dateChange = async (value) => {
    console.log("from_date_value",value);
    setFromDate(value?.$d);
  }

  const onto_dateChange = async (value) => {
    console.log("to_date_value",dayjs(value));
    setToDate(value?.$d);
  }

  return (
    <>
      {/* <HeaderSearch /> */}
      <Box sx={{ px: 2, pb: 1, pt: 4, [theme.breakpoints.down("md")]: { px: 0, pt: "20px" }, }}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "27.17px",
            color: "#801F52",
            [theme.breakpoints.down("md")]: { textAlign: "center" },
          }}
        >
          {checkInData?.loginId?.displayName+"'s "} &nbsp;
          Dashboard{" "}
        </Typography>
        <Divider sx={{borderColor: "#FBC7A4"}} />
        <Box
          container
          className={
            "grid grid-flow-row-dense grid-cols-1 md:grid-cols-3 h-96 gap-2 overflow-y-auto pt-3 "
          }
          sx={{
            height:
              screenSize.width < 768
                ? screenSize.height - 80 + "px"
                : screenSize.height - 140 + "px",
          }}
        >
          <Box className={" md:col-span-2 h-full w-full"}>
            {/* <Box className="flex gap-3 px-4 py-3 items-baseline">
              <IconButton
                aria-label="delete"
                size="large"
                sx={bodyStyle.arrowBTN}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Card sx={bodyStyle.carouselCards} className="grow"></Card>
              <Card sx={bodyStyle.carouselCards} className="grow"></Card>
              <Card sx={bodyStyle.carouselCards} className="grow"></Card>
              <IconButton
                aria-label="delete"
                size="large"
                sx={bodyStyle.arrowBTN}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box> */}

            <Box className="flex mb-3 justify-end md:justify-between items-center flex-wrap gap-1">
              {/* today's day section */}
              <Box sx={{
                height: "40px",
                padding: "10px",
                borderRadius: "50px",
                border: "0.75px 0px 0px 0px",
                boxShadow: "4px 4px 40px 0px #0000000D",
                border: "0.75px solid #801F52",
                fontFamily: "'Poppins', san-sarif",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "17.29px",
                color: "#801F52",
                background: "#DFC7D4",
                width: "26%",
                textAlign: "center",
                [theme.breakpoints.down("md")]: { width: "100%" },
              }}>
                {dayjs(Date.now()).format("dddd, DD/MM/YYYY")}
              </Box>
                <Box className="" sx={{width: "175px", [theme.breakpoints.down("md")]: { width: "37%" },}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="From Date"
                        format="DD-MMM-YYYY"
                        sx={bodyStyle.DatePickerStyle}
                        minDate={dayjs("01/01/2024")}
                        maxDate={todayDate && dayjs()}
                        onChange={(value)=>onfrom_dateChange(value)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box className="" sx={{width: "175px",[theme.breakpoints.down("md")]: { width: "37%" },}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="To Date"
                        format="DD-MMM-YYYY"
                        sx={bodyStyle.DatePickerStyle}
                        minDate={dayjs("01/01/2024")}
                        maxDate={todayDate && dayjs()}
                        onChange={(value)=>{onto_dateChange(value)}}
                      />
                    </DemoContainer>
                  </LocalizationProvider></Box>
              <Button sx={bodyStyle.saveBtn} onClick={()=>{getUserAttendance()}}>
                {
                  screenSize.width < 768 ? <Search /> : 'Fetch Data'
                }
              </Button>
            </Box>

            <Box className="dashboardAttendanceTable" sx={{...bodyStyle.attendanceTableSection, height: screenSize.width < 768 ? screenSize.height - 110 + "px" : screenSize.height - 240 + "px",}}>
              <table className="w-full lookupTypeTable">
                <thead className="tableHeader dashboardTable">
                  <th>Date </th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Status</th>
                </thead>
                <tbody className="tableBody">
                  {attendanceLoading ? (
                    <tr>
                      {" "}
                      <td className="text-center" colSpan={12}>
                        {" "}
                        Loading...{" "}
                      </td>{" "}
                    </tr>
                  ) : attendanceData && attendanceData.length ? (
                    attendanceData.map((item, index) => {
                      return (
                        <tr
                          className="py-2 px-2 dashboardTable"
                          key={index}
                          style={bodyStyle.tableBodyRow}
                        >
                          <td style={{ ...bodyStyle.tableBodyData }}>
                            {" "}
                            {dayjs(item?.date).format("DD-MMM-YYYY")}{" "}
                          </td>
                          <td style={bodyStyle.tableBodyData}>
                            {" "}
                            {item.in_date
                              ? dayjs(item?.in_date).format("LT")
                              : "-"}{" "}
                          </td>
                          <td style={bodyStyle.tableBodyData}>
                            {" "}
                            {item.out_date
                              ? dayjs(item?.out_date).format("LT")
                              : "-"}{" "}
                          </td>
                          <td
                            style={{
                              ...bodyStyle.tableBodyData,
                              color:
                                item.status.toLowerCase() === "absent"
                                  ? "#FF3434"
                                  : "#0485E1",
                            }}
                          >
                            {" "}
                            {item.status}{" "}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center pt-6 font-bold">
                        {" "}
                        No Data Found{" "}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Box>
          </Box>
          <Box className={"h-full w-full p-0"}>
            <Box
              className="pb-1 flex flex-col items-center"
              sx={{  borderRadius: "15px" }}
            >
              <Box className="bg-[#FF914E] mt-2" sx={bodyStyle.CalenderBox}>
                <Box className="flex justify-between px-2 py-2">
                  <Typography sx={bodyStyle.CalenderHeadingTitle}>
                    {" "}
                    {dayjs(currentTime).format("DD MMM YYYY")}
                  </Typography>
                  <Typography sx={bodyStyle.CalenderHeadingTitle}>
                    {" "}
                    {dayjs(currentTime).format("LT")}
                  </Typography>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    disablePast
                    disableFuture
                    defaultValue={today}
                    sx={{
                      boxShadow: "6px 6px 20px 0px #0000001A",
                      borderRadius: "10px",
                      height: "280px",
                      bgcolor: "#fff",
                      ".MuiPickersCalendarHeader-root": {
                        backgroundColor: "#FF914E",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        padding: "20px",
                        marginTop: "0px",
                        display: "none",
                      },
                      ".MuiDayCalendar-weekDayLabel": {
                        height: "42px",
                        width: "45px",
                        color: "#595959",
                        fontWeight: 600,
                      },
                      ".MuiPickersDay-dayWithMargin": {
                        height: "42px",
                        width: "45px",
                      },
                      ".MuiPickersCalendarHeader-label": {
                        textAlign: "center",
                        color: "#fff",
                      },

                      ".MuiPickersArrowSwitcher-button": {
                        paddingRight: "7px",
                        color: "#fff",
                      },
                      "& .MuiPickersDay-root": {
                        "&.Mui-selected": {
                          backgroundColor: "#FF914E",
                          height: "34px",
                          width: "40px",
                          borderRadius: "5px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="flex flex-col justify-center items-center w-full mt-2">
                <Button
                  className="w-full"
                  disabled
                  sx={{ ...bodyStyle.checkInDataBTN, display: "inline-flex" }}
                >
                  <LocationOnIcon
                    sx={{
                      fontSize: "16px",
                      mx: 0.5,
                      [theme.breakpoints.down("md")]: { fontSize: "14px" },
                    }}
                  />
                   <>Location</>
                  <marquee width="60%" scrollamount="3" style={bodyStyle.buttonInside}>
                    {checkData?.check_in_address}
                  </marquee>
                </Button>
                <Button
                  className="w-full"
                  disabled
                  sx={bodyStyle.checkInDataBTN}
                >
                  <LockClockIcon
                    sx={{
                      fontSize: "16px",
                      mx: 0.5,
                      [theme.breakpoints.down("md")]: { fontSize: "14px" },
                    }}
                  />
                  {"Checked-in "} 
                  <Typography style={bodyStyle.buttonInside}>
                    {dayjs(checkData.check_in_date).format("LT")}
                  </Typography>
                </Button>
                <Button
                  className="w-full"
                  onClick={() => {
                    OnCheckOutClick();
                  }}
                  sx={{ ...bodyStyle.checkInDataBTN }}
                >
                  <LockClockIcon
                    sx={{
                      fontSize: "16px",
                      mr: "5px",
                      [theme.breakpoints.down("md")]: { fontSize: "14px" },
                    }}
                  />
                  {checkOutLoading ? (
                    <CircularProgress sx={{ color: "#801F52" }} size={"20px"} />
                  ) : (
                    <>Check Out</>
                  )}
                </Button>
              </Box>
            </Box>
            {/* <Box
              className={"flex items-center bg-white"}
              sx={bodyStyle.innovateSection}
            >
              <img src={innovationImage} alt="Innovation Image" />
              <Typography sx={bodyStyle.innovateText}>
                Innovate, <span style={{ color: "#FF914E" }}>Build</span> and
                Scale!
              </Typography>
            </Box> */}
              <Box sx={bodyStyle.leaveSection} className="mx-5">
              <Box className={"flex p-0 pl-2"}>
                <img src={DashboardCalender} alt="Calender Image" />
                <Box className="flex-grow" sx={bodyStyle.leaveLeft}>
                  <Typography sx={bodyStyle.LeaveLeftHeading}>
                    Leave Balances
                  </Typography>
                  <Divider
                    // variant="middle"
                    sx={{ borderColor: "#FFFFFF50", borderWidth: "1px" }}
                  />
                  <Box className="flex">
                    <Box className="flex gap-4">
                      <Box className="flex">
                        <span >
                          {" "}
                          <Typography sx={bodyStyle.LeaveLeftValues}>
                            {" "}
                            Available{" "}
                          </Typography>{" "}
                        </span>
                        <Typography sx={bodyStyle.LeaveLeftValues}>
                          {" "}
                          :  {leavesBalances.available}{" "}
                        </Typography>
                      </Box>
                      <Box className="flex">
                        <span >
                          {" "}
                          <Typography sx={bodyStyle.LeaveLeftValues}>
                            {" "}
                            Booked{" "}
                          </Typography>{" "}
                        </span>
                        <Typography sx={bodyStyle.LeaveLeftValues}>
                          {" "}
                          :  {leavesBalances.booked}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <Box className="overflow-y-auto" sx={{ height: "155px" }}>
                  <Typography
                    className="text-center md:text-left"
                    sx={bodyStyle.leaveRightSectionText}
                  ></Typography>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <FeedBackForm />
      </Box>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435, background: '#838383', borderRadius: '24px' } }}
        maxWidth="xs"
        open={openCheckOutDialog}
        >
          <DialogTitle sx={{color: '#fff', fontWeight: 600}}> {"Reason for Early Check-Out"} </DialogTitle>
          <Box className="bg-white mx-4 mb-4 rounded-2xl">
          <DialogContent sx={{p: "10px"}}>
            <FormControl error={earlyCheckOutReasonError} variant="standard" fullWidth>
              <textarea className="w-full rounded-md" placeholder="Please share your reason" rows={4} 
              value={earlyCheckOutReason}
              onInput={(e)=>{
                setEarlyCheckOutReason(e.target.value)
              }}
              style={{'padding': '10px', color: '#801F52', outline: 'none', fontSize: '14px'}} />
              {
                earlyCheckOutReasonError && earlyCheckOutReason.trim().length < 1 ? 
                  <FormHelperText id="component-error-text">Required</FormHelperText>
                :null
              }
            </FormControl>
          </DialogContent>
          <DialogActions sx={{pt:0}}>
            <Button disabled={checkOutLoading} sx={{background: "#801F52", color: "#fff", height: "22px", fontSize: '11px', fontWeight: 600, "&:hover":{ background: "#801F52", color: "#fff", transform: "scale(1.1)" }}} onClick={()=>{validateCheckoutReason()}}>
              {
                checkOutLoading ? 
                    <CircularProgress
                      sx={{ color: "#801F52" }}
                      size={"20px"}
                    />
                : 'Submit'
              }
              
            </Button>
          </DialogActions>
          </Box>
          <IconButton aria-label="delete" className="right-2 top-3" sx={{ position: 'absolute' }} onClick={()=>{setOpenCheckOutDialog(false)}}>
            <CloseIcon className="text-white" />
          </IconButton>
      </Dialog>
    </>
  );
}

export default DashboardBody;

const bodyStyle = {
  arrowBTN: {
    bgcolor: "#fff",
    height: "30px",
    width: "30px",
    "&:hover": {
      bgcolor: "#fff",
    },
  },
  carouselCards: { bgcolor: "#fff", minWidth: "30px", minHeight: "130px" },
  leaveSection: {
    bgcolor: "#FF914E",
    borderRadius: "15px",
    p: "4px",
  },
  leaveLeft: {
    boxShadow: "6px 6px 40px 0px #0000000A",
    mx: "10px",
    my: "4px",
  },
  LeaveLeftHeading: {
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19.76px",
    mb: 1,
    color: "#FFF",
  },
  leaveRightSectionText: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16.8px",
    maxWidth: "90%",
    color: "#FFF",
  },
  LeaveLeftValues: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "11.9px",
    my: 1,
    color: "#FFF",
  },
  checkInDataBTN: {
    my: "5px",
    pl: 1,
    bgcolor: "#FF914E",
    borderRadius: "50px",
    color: "#FFF",
    width: "100%",
    maxWidth: "320px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "center",
    display: "flex",
    flexWrap: "no-wrap",
    "&:hover": {
      bgcolor: "#801F52",
      color: "#fff",
    },
    "&:disabled": {
      color: "#FFF !important",
    },
  },
  CalenderBox: {
    boxShadow: "0px 1px 5px 0px #0000001A",
    borderRadius: "10px",
    width: "auto",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  CalenderHeadingTitle: {
    fontWeight: 500,
    fontSize: "18px",
    color: "#fff",
  },
  btnTextLowOpacity: {
    color: "#909090",
  },
  innovateSection: {
    boxShadow: "6px 4px 20px 0px #0000000D",
    mt: 1,
    borderRadius: "15px",
  },
  innovateText: {
    fontSize: "24px",
    color: "#801F52",
    fontWeight: 600,
    lineHeight: "29.65px",
  },
  attendanceTableSection: {
    boxShadow: "4px 4px 20px 0px #0000000D",
    bgcolor: "#fff",
    mt: 1,
    px: 2,
    pt: 2,
    borderRadius: "15px",
    overflowY: "auto",
  },
  tableBodyRow: {
    borderRadius: "12px 12px 12px 12px",
    height: "40px",
    boxShadow: "-1px 1px 0px 0px #00000014",
    border: "none",
  },
  tableBodyData: {
    textAlign: "left",
    minWidth: "50px",
    margin: "10px",
    // backgroundColor: "#FFFFFF",
    color: "#801F52",
    paddingRight: "10px",
  },
  buttonInside:{
    background: "#fff",
    color: "#801F52",
    borderRadius: "20px",
    padding: "2px 10px",
    width: "60%",
    marginLeft: "auto",
  },
  saveBtn:{
    color: "#FFF",
    backgroundColor: "#801F52",
    padding: "10px 30px 10px 30px",
    borderRadius: "50px",
    lineHeight: "19.76px",
    fontWeight: 700,
    "&:hover":{
      color: "#801F52",
      background: "#FFF",
      border: "1px solid #801F52",
    },
    [theme.breakpoints.down("md")]: { py: "8px" },
  },
  DatePickerStyle: {
    "&.MuiTextField-root":{
      width: "170px !important",
      minWidth: "170px !important",
      [theme.breakpoints.down("md")]: { minWidth: "100% !important", width: "100% !important" },
    },
    "& .MuiInputBase-formControl": {
      background: "#fff",
      borderRadius: "30px",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      mb: 0.5
    },
    "& .MuiOutlinedInput-input":{
        py: "9px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormLabel-root":{
        top: "-7px",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink":{
        transform: "translate(14px, -3px) scale(0.75)",
    }
  },
};