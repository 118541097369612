import * as React from 'react';

import { unstable_useForkRef as useForkRef } from '@mui/utils';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { unstable_useDateField as useDateField } from '@mui/x-date-pickers/DateField';
import CalendarIcon from '@mui/icons-material/CalendarToday'; 

import { useClearableField } from '@mui/x-date-pickers/hooks';
import dayjs from 'dayjs';

const BrowserField = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    label,
    inputRef,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
    error,
    focused,
    ownerState,
    sx,
    ...other
  } = props;

  const handleRef = useForkRef(containerRef, ref);

  return (
    <Box
      sx={{ ...(sx || {}), display: 'flex', alignItems: 'center', background: '#fff', px: '8px', py: '6px', borderRadius: '50px', boxShadow: "4px 4px 40px 0px #0000000D",    }}
      id={id}
      ref={handleRef}
    >
      {startAdornment}
      <input disabled={true}  ref={inputRef} {...other} style={{maxWidth:'100px', fontSize: '14px', pl: 2, background: '#fff'}} />
      {endAdornment}
    </Box>
  );
});

const BrowserDateField = React.forwardRef((props, ref) => {
  const { inputRef: externalInputRef, slots, slotProps, ...textFieldProps } = props;

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useDateField({
    props: textFieldProps,
    inputRef: externalInputRef,
  });

  /* If you don't need a clear button, you can skip the use of this hook */
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
    useClearableField({
      onClear,
      clearable,
      fieldProps,
      InputProps: fieldProps.InputProps,
      slots,
      slotProps,
    });
  return (
    <BrowserField
      ref={ref}
      inputRef={inputRef}
      {...processedFieldProps}
      InputProps={{...ProcessedInputProps}}
      endAdornment={{ml:-1}}
    />
  );
});

export default function FilterDatePicker({value="", min = new Date(), onChangeValue, placeholder="Select Date" }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <DatePicker
            slots={{ field: BrowserDateField }}
            id="from-date"
            slotProps={{
                field: { clearable: true, placeholder: placeholder },
            }}
           
            minDate={dayjs(min)}
            format="DD-MMM-YYYY"
            value={value ? dayjs(value) : null}
            onChange={(value)=>onChangeValue(value)}
        />
      </Box>
    </LocalizationProvider>
  );
}