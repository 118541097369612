import React, { useEffect, useState } from "react";
import "../../LookupTypes/LookupTypes.css";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { Popover, Typography, createTheme } from "@mui/material";
const theme = createTheme();

function HolidaysDataTable(props) {
  let { header = [], tableData: data = [] } = props;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    setTableData(data);
    setLoading(false);
  }, []);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <div>
      <div
        className="overflow-y-auto overscroll-x-none "
        style={{
          width:
            screenSize.width < 768 ? screenSize.width : screenSize.width - 420,
          height:
            screenSize.width < 768
              ? screenSize.height - 150
              : screenSize.height - 320,
        }}
      >
        <table className="w-full lookupTypeTable">
          <thead className="tableHeader">
            <tr>
            <th style={{ width: "20px" }}> </th>
            <th>Holidays</th>
            <th>Date</th>
            <th></th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {loading ? (
              <tr>
                {" "}
                <td className="text-center" colSpan={12}>
                  {" "}
                  Loading...{" "}
                </td>{" "}
              </tr>
            ) : tableData && tableData.length ? (
              tableData.map((item, index) => {
                return (
                  <tr className="tableBodyRow py-2" key={index}>
                    <td className="p-0 padTop-0" style={{ width: "20px" }}></td>
                    <td>{TextField(item.lookup_code_name)}</td>
                    <td>{TextField(item.lookup_code_description)}</td>
                    <td></td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center pt-6 font-bold">
                  {" "}
                  No Data Found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HolidaysDataTable;

const TextField = (value) =>{
  return (<Typography
    paddingRight={2}
    borderRight={1}
    sx={{
      typography: {
        fontSize: "14px",
        maxWidth: "325px",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.7rem",
        },
      },
    }}
    borderColor={"#94B1CB"}
    marginY={1}
    noWrap
  >
    {value}
  </Typography>
  )
} 