import React, { useEffect, useState } from "react";
import "../../../../Common/css/Form.css";
import { useParams } from "react-router-dom";
import { DatabaseService } from "../../../../Services/DatabaseService";
import bellSVG from "../../../../Assets/bell.svg";
import messageSVG from "../../../../Assets/Message.svg";
import TicketForm from "./TicketForm";



function AddTicket() {
  const [fullLoading, setFullLoading] = useState(false);
  

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      getTypeData();
    }
  }, []);

  const getTypeData = async () => {
    setFullLoading(true);
    let response = await DatabaseService("GET", "lookup/type/getBy", null, id);
    if (response.code == 200) {
      let data = response.data;

      setFullLoading(false);
    } else {
      alert("Please Try After Sometime.");
      setFullLoading(false);
    }
  };

  return (
    <div>
      {fullLoading ? (
        <div className="flex flex-col items-center h-96">
          <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
            <div className="flex justify-center">
              <div
                className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-5 pt-1 px-3">
          <div className="chatHeader my-1 ">
            <div className="w-full rounded-full flex px-0 md:px-3 justify-end">
              <div className="flex-grow-0 pl-2">
                <button className="notificationBtn" type="reset">
                  <img src={messageSVG} />
                </button>
              </div>
              <div className="flex-grow-0 pl-2">
                <button className="notificationBtn" type="reset">
                  <img src={bellSVG} />
                </button>
              </div>
            </div>
          </div>
          <TicketForm  />
        </div>
      )}
    </div>
  );
}
export default AddTicket;

