import React ,{ useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import './LookupCodeForm.css';
import PlusSVG from '../../../../Assets/plus.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { DatabaseService } from '../../../../Services/DatabaseService';

function LookupCodeForm () {
    const [lookupTypeEnums, setLookupTypeEnums] = useState([]);
    const [lookupTypeList, setLookupTypeList] = useState([]);
    const [lookup_type_name, setLookupTypeName] = useState('');
    const [lookup_type_description, setLookupTypeDescription] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fullLoading, setFullLoading] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [attributesList, setAttributesList] = useState([{code_attribute_name:'',code_attribute_description:''}]);

    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');

    let { id } = useParams(); 

    const navigate = useNavigate();

    useEffect(()=>{
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    },[screenSize]);

    useEffect(()=>{
        getTypeList();
        if(id){
            getLookupCodesData();
        }
    },[]);

    const getTypeList = async () =>{
        setFullLoading(true);
        let response = await DatabaseService('GET', 'lookup/type', null);
        if(response.code == 200){
            let data = response.data;
            setLookupTypeList(data);
            let TypeList = await data?.map((type)=>{return type.lookup_type_name});
            setLookupTypeEnums(TypeList);
            setFullLoading(false);
        }else{
            alert('Please Try After Sometime.')
            setFullLoading(false);
        }
    }

    const handleAddButton = () =>{
        let attributeCount = attributesList.length;
        if(attributeCount>9){
            return
        }
        setAttributesList([...attributesList,{code_attribute_name:'', code_attribute_description:''}]);
    };

    const getLookupCodesData = async () =>{
        setFullLoading(true);
        let codeData = await DatabaseService('GET', 'lookup/code/getBy', null, id);
        console.log("codeData-------------->",codeData);
        if(codeData.code == 200){
            let { lookup_code_name = "", lookup_code_description="", lookupType : { lookup_type_name = "" } } = codeData.data;
            setLookupTypeName(lookup_code_name);
            setLookupTypeDescription(lookup_code_description);
            setValue(lookup_type_name);
            setInputValue(lookup_type_name);
            let attributes = codeData?.data?.attribute?.map((attribute, index)=>{ return { code_attribute_name: attribute.code_attribute_name || '' ,code_attribute_description: attribute?.code_attribute_description ||'' } });
            setAttributesList(attributes);
            setFullLoading(false);
        }else{
            setFullLoading(false);
        }
    }

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    const handleSubmit = async () =>{
        setSubmitError(false);
        setLoading(true);
        let result = allValuesHaveValue(attributesList);
        if( !result || lookup_type_description.trim().toString() == "" || lookup_type_name.trim().toString() == "" || value == "" ){
            setSubmitError(true);
            setLoading(false);
            return
        }
        let obj = { 
            "lookup_code_name": lookup_type_name,
            "lookup_code_description": lookup_type_description,
            "lookupTypeId" : await getLookupTypeId(),
            "attribute" : attributesList
        };
        
        let response =   id? await DatabaseService('PUT', 'lookup/code', obj, id) : await DatabaseService('POST', 'lookup/code', obj);
        if(response.code == 200){
            setLoading(false);
            navigate('/dashboard/lookup_code');
            alert("Lookup Code Submitted Successfully.");
        }else{
            setLoading(false);
            alert("Please Try After SomeTime.");
        }
    }

    const getLookupTypeId = async () => {
        let typeId = await lookupTypeList.find((type, index)=> type.lookup_type_name.trim().toLowerCase().toString() == value.trim().toLowerCase().toString())._id;
        return typeId
    }

    const allValuesHaveValue = (data) => {
        return data.every((item) =>
          Object.values(item).every((value) => typeof value === 'string' && value.trim() !== '')
        );
      };

    const handleCancel = () => {
        navigate('/dashboard/lookup_code')
    }

    return (
        <div>
            {
                fullLoading ? 
                <div className="flex flex-col items-center h-96">
                    <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                    <div className="flex justify-center">
                        <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </div>
                </div>
                :
                <div className="pb-5 pt-14 md:pt-5 px-3">
                    <div className="grid grid-cols-3 gap-4">
                    <div className="">
                        <label className="block text-sm font-medium mb-2">Lookup Code</label>
                        <input type="text" id="input-label1" className="py-3 px-4 block rounded-full text-sm shadow-md w-full outline-none" 
                        required placeholder="Code" 
                        onChange={(e)=>{
                            setLookupTypeName(e.target.value);
                        }}
                        value={lookup_type_name}
                        />
                    </div>
                    <div className="col-span-2">
                        <label className="block text-sm font-medium mb-2">Description</label>
                        <textarea type="text" id="input-label2" className="py-3 px-4 block rounded-3xl text-sm shadow-md w-full outline-none" 
                        required rows={1} placeholder="Description" 
                        onChange={(e)=>{setLookupTypeDescription(e.target.value)}} 
                        value={lookup_type_description}
                        />
                    </div>
                    </div>
                    <div className="py-2" >
                        <div className='attributeSection pt-3' >
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                            setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={lookupTypeEnums}
                            
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                  <input type="text" {...params.inputProps} 
                                  className='py-3 px-4 block text-sm w-full outline-none rounded-3xl shadow-md mb-2' placeholder='Lookup Type' />
                                </div>
                              )}
                        />                           
                        </div>
                    </div>

                    <div className="py-2" >
                        <div className="grid grid-cols-3 gap-4 mb-3 px-4 py-3 AttributesHeader shadow-md">
                            <div className="" >Attributes</div>
                            <div className="col-span-2" >Description</div>
                        </div>
                        <div className='attributeSection ' style={{maxHeight: screenSize.width<768 ? screenSize.height-360 :screenSize.height-370}}>
                        {
                                attributesList.map((attribute, index)=>{
                                    return <div className="grid grid-cols-3 gap-4 relative py-2 rounded-3xl bg-white mb-2 shadow-md" key={index}>
                                        <div className="">
                                            <input type="text" id={"input-label"+index} className="py-2 px-4 block ml-2 text-sm w-full outline-none" 
                                            required placeholder={"Attribute "+(index+1)} 
                                            onInput={(e) => {
                                                setAttributesList((prevArr) => {
                                                  const result = [...prevArr];
                                                  result[index].code_attribute_name = e.target.value;
                                                  return result;
                                                });
                                              }}
                                            value={attribute.code_attribute_name} />
                                        </div>
                                        <div className="col-span-2">
                                            <textarea type="text" id={"input-label"+index} className="py-2 px-4 mr-4 rounded-se-3xl block text-sm w-full outline-none" 
                                            required rows={1} placeholder={"Description "+(index+1)}  
                                            onInput={(e) => {
                                                setAttributesList((prevArr) => {
                                                  const result = [...prevArr];
                                                  result[index].code_attribute_description = e.target.value;
                                                  return result;
                                                });
                                              }}
                                            value={attribute.code_attribute_description}
                                            />
                                        </div>
                                        {/* <button className='crossButton absolute right-2 -top-3' onClick={()=>{handleRemoveAttr(index)}} >x</button> */}
                                    </div>
                                })
                        }
                        </div>
                    </div>

                    {submitError ?
                    <p className='text-center text-xs text-red-600' >All Fields Are Require</p>
                    : null}
                   <button className="addAttributeBtn" type='button' onClick={()=>{handleAddButton()}}>
                            <img src={PlusSVG} />
                    </button>
                    <div className='flex absolute bottom-5 md:bottom-10 right-2 md:right-12'>
                        <button className="saveBtn" type='submit' disabled={loading} onClick={()=>{handleSubmit()}}>
                                SAVE
                        </button>
                        <button className="cancelBtn" type='button' onClick={()=>{handleCancel()}}>
                                CANCEL
                        </button>
                    </div>
                </div>

            }

        </div>
    )
}
export default LookupCodeForm;