import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import TicketCard from "./TicketCard";

function TicketSection() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          className="ticketSection"
          columns={{ xs: 2, sm: 4, md: 6, lg: 9, xl: 12 }}
          sx={{
            maxHeight:
              screenSize.width < 768
                ? screenSize.height - 417
                : screenSize.height - 262,
            paddingTop: 1,
          }}
        >
          {Array.from(Array(6)).map((item, index) => (
            <TicketCard obj={item} index={index} />
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default TicketSection;
