import React from 'react';
import bellSVG from "../../Assets/bell.svg";
import messageSVG from "../../Assets/Message.svg";
import { Typography, createTheme } from '@mui/material';

const theme = createTheme();

function SearchLessHeader() {
    return (
        <div>
          <div className="chatHeader my-1 md:my-3">
            <div className="w-full rounded-full flex px-0 md:px-3">
              <div className=" flex items-center flex-grow ml-11 md:ml-0 mr-2">
                <Typography sx={{
                    typography : {
                        fontSize: "22px",
                        fontWeight: "700",
                        lineHeight: "27.17px",
                        color: '#FF914E',
                        [theme.breakpoints.down("md")]: { fontSize: "16px" },
                    }
                }} >FPM</Typography>
              </div>
               <div className="flex-grow-0 pl-2">
                <button className="notificationBtn" type="reset">
                  <img src={messageSVG} />
                </button>
              </div>
              <div className="flex-grow-0 pl-2">
                <button className="notificationBtn" type="reset">
                  <img src={bellSVG} />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
}

export default SearchLessHeader;