import { useNavigate } from "react-router-dom";

const navigate = useNavigate

export const utils = {
    async storeData(key, value) {
        try {
            const jsonValue = JSON.stringify(value);
            await localStorage.setItem(key, jsonValue)
        } catch (e) {
            // saving error
        }
    },

    async getData(key) {
        try {
            const jsonValue = await localStorage.getItem(key);
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch (e) {
            // error reading value
        }
    },
    
    async storeStringData(key, value) {
        try {
            await localStorage.setItem(key, value);
        } catch (e) {
            // saving error
        }
    },

    async getStringData(key) {
        try {
            const jsonValue = await localStorage.getItem(key);
            return jsonValue;
        } catch (e) {
            // error reading value
        }
    },


    async clearAllData(){
        try {
             await localStorage.clear();
             return true;
        } catch (e) {
            console.log(e);
            // error reading value
        }
    },

    async checkLoggedIn(){
        return new Promise( async(resolve, reject) => {
            let userToken = localStorage.getItem('userToken');
            if(!userToken){
                // navigate('/');
                resolve(false);
            }else{
                let tokenData = await JSON.parse(atob(userToken.split(".")[1]));
                let dateTime = new Date(tokenData.exp * 1000).getTime();
                const currentTimestamp = new Date().getTime(); 
                console.log("date_condition------------------->",dateTime<currentTimestamp);
                if (dateTime < currentTimestamp) {
                    // navigate('/');
                    resolve(false)
                }else{
                    resolve(userToken)
                }
            }
        });
    },

    async getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
      },

    async isValidUrl (urlString) {
        try {
            var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
            return !!urlPattern.test(urlString);
        } catch (error) {
            return false
        }
    },
}
