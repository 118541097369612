import React, { useEffect, useState } from "react";
import "../../LookupTypes/LookupTypes.css";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { Popover, Typography, createTheme } from "@mui/material";
const theme = createTheme();

function LeaveTypeDataTable(props) {
  let { header = [], tableData: data = [] } = props;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    setTableData(data);
    setLoading(false);
  }, []);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <div>
      <div
        className="overflow-y-auto overscroll-x-none bg-white px-2"
        style={{
          height:
            screenSize.width < 768
              ? screenSize.height - 150
              : screenSize.height - 195,
          borderRadius: "16px",
          boxShadow: "4px 4px 20px 0px #0000000D"
        }}
      >
        <table className="w-full lookupTypeTable">
          <thead className="tableHeader">
            <tr>
              <th style={{ width: "20px" }} key={"001"}> </th>
              <th key={"002"}>{"Leave Type"}</th>
              <th key={"003"}>{"Quantum"}</th>
              <th key={"004"}></th>
              <th key={"005"}></th>
              <th key={"006"}></th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {loading ? (
              <tr>
                {" "}
                <td className="text-center" colSpan={12}>
                  {" "}
                  Loading...{" "}
                </td>{" "}
              </tr>
            ) : tableData && tableData.length ? (
              tableData.map((item, index) => {
                return (
                  <tr className="tableBodyRow py-2" key={index}>
                    <td key={index+"000"} className="p-0 padTop-0" style={{ width: "20px" }}></td>
                    <td key={index+"001"}>
                      <Typography
                        paddingRight={2}
                        borderRight={1}
                        sx={{
                          typography: {
                            fontSize: "14px",
                            maxWidth: "325px",
                            [theme.breakpoints.down("md")]: {
                              fontSize: "0.7rem",
                            },
                          },
                        }}
                        borderColor={"#94B1CB"}
                        marginY={1}
                        noWrap
                      >
                        {item.type}{""}
                      </Typography>
                    </td>
                    <td key={index+"002"}>
                      <Typography
                        paddingRight={2}
                        sx={{
                          typography: {
                            fontSize: "14px",
                            maxWidth: "310px",
                            [theme.breakpoints.down("md")]: {
                              fontSize: "0.7rem",
                            },
                          },
                        }}
                        marginY={1}
                        noWrap
                      >
                        {item.quantum}{""}
                      </Typography>
                    </td>
                    <td key={index+"003"}> </td>
                    <td key={index+"004"}> </td>

                    <td className="actionCol" key={index+"005"}>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          title="Info"
                          onClick={(event) => handleClick(event, item)}
                        >
                          <InfoIcon />
                        </button>
                        <Popover
                          id={item.type}
                          open={anchorEl !== null && selectedItem === item}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPopover-paper": {
                              background: "#94B1CB",
                              border: "1px solid #221F4B",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          <Typography sx={{ p: 2, width: "400px" }}>
                            {item.description}{""}
                          </Typography>
                        </Popover>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center pt-6 font-bold">
                  {" "}
                  No Data Found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LeaveTypeDataTable;
