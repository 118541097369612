import {
  Button,
  Divider,
  FormControl,
  InputBase,
  styled,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #FF914E",
    borderRadius: 50,
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: 150,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "Poppins",
    ].join(","),
    "&:focus": {
      borderRadius: 50,
    },
  },
}));

function FilterTickets() {
  return (
    <div>
      <div className="w-full flex">
        <FormControl sx={{ m: 1 }} variant="standard">
          <select
            style={{
              padding: "2px 10px",
              border: "2px solid #FF914E",
              borderRadius: "50px",
            }}
          >
            <option aria-label="None" value="" />
            <option value={10}>Project</option>
            <option value={20}>Project</option>
            <option value={30}>Project</option>
          </select>
        </FormControl>
      </div>
      <Divider />
      <div className="ml-4 mt-2">
        <Button type="button" sx={{ color: "#94B1CB", borderRadius: "10px" }}>
          <AddIcon />
          Add Filter
        </Button>
      </div>
    </div>
  );
}

export default FilterTickets;
