import React from "react";
import searchSVG from "../../../../Assets/search.svg";
import bellSVG from "../../../../Assets/bell.svg";
import PlusSVG from "../../../../Assets/plus.svg";
import messageSVG from "../../../../Assets/Message.svg";
import { Link } from "react-router-dom";

function SearchTicket() {
  return (
    <div>
      <div className="chatHeader my-1 md:my-3">
        <div className="w-full rounded-full flex px-0 md:px-3">
          <div className=" flex justify-center items-center flex-grow ml-11 md:ml-0 mr-2">
            <button
              disabled
              type="reset"
              className="flex w-10 items-center justify-center leftSearchIcon z-10"
            >
              <img src={searchSVG} />
            </button>
            <input
              type="text"
              className="w-full  -ml-4 h-8 pl-6 text-base font-semibold outline-0 rounded-r-3xl searchInput"
              placeholder=""
              id=""
            />
            {/* <input type="button" value="Buscar" className="bg-blue-500 p-2 rounded-tr-lg rounded-br-lg text-white font-semibold hover:bg-blue-800 transition-colors"/> */}
          </div>
          <div className="flex-grow-0">
            <Link to={"/dashboard/tickets/add"} className="notificationBtn">
              <img src={PlusSVG} />
            </Link>
          </div>
          <div className="flex-grow-0 pl-2">
            <button className="notificationBtn" type="reset">
              <img src={messageSVG} />
            </button>
          </div>
          <div className="flex-grow-0 pl-2">
            <button className="notificationBtn" type="reset">
              <img src={bellSVG} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchTicket;
