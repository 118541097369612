import React from "react";

function FPM_white(props) {
    const { color= "#F3F9FF" } = props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        fill="none"
        viewBox="0 0 14 18"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.513 2.877a1.682 1.682 0 00-.075.498c0 .31.252.563.562.563h3.375a.562.562 0 00.563-.563c0-.169-.026-.337-.075-.498m-4.35 0a1.688 1.688 0 011.612-1.19H9.25a1.687 1.687 0 011.613 1.19m-4.35 0c-.282.017-.563.038-.843.06-.849.07-1.482.793-1.482 1.644v1.606m6.675-3.31c.282.017.562.038.843.06.848.07 1.482.793 1.482 1.644v7.794a1.687 1.687 0 01-1.688 1.688H9.812M4.189 6.186H1.655a.844.844 0 00-.843.844v8.438c0 .466.377.844.843.844H8.97a.844.844 0 00.844-.844v-1.406M4.186 6.186H8.97c.466 0 .844.378.844.844v7.032m-6.188-2.25l1.125 1.124L7 10.126"
        ></path>
      </svg>
    );
  }

export default FPM_white;