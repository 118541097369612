import React, { useEffect, useState } from 'react';
import "./main.css";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthCommon from './Auth/AuthCommon';
import GoogleLogin from './Auth/GoogleLogin/GoogleLogin';
import CheckIn from './Auth/CheckIN/CheckIn';
import { utils } from '../Common/Utills';
import { login} from '../Redux/sessionSlice';
import Dashboard from './Screens/Dashboard/Dashboard';
import Protected from './Auth/Protected/Protected';

function Main() {
    const isAuthenticated = useSelector((state) => state?.session?.isAuthenticated);
    const dispatch = useDispatch();

    
    useEffect(()=>{
      checkLoggedIn();
    },[]);

    const checkLoggedIn= async () =>{
      let checkLoggedIn = await utils.checkLoggedIn().then((response)=>{return response});
      if(checkLoggedIn){
          dispatch(login(checkLoggedIn));
      }
    }

    const router = createBrowserRouter([
      {
        path: '/',
        element : <AuthCommon isAuthenticated={isAuthenticated} children={ isAuthenticated ? <CheckIn /> : <GoogleLogin />} />
      },
      {
        path:'/dashboard/*',
        element: <Protected Component={Dashboard} />
      }
    ])

    return (
        <>
          <RouterProvider router={router} />
        </>
     );
}

export default Main;