import { useLoaderData } from 'react-router-dom';
import './AuthCommon.css';
import FeedBackForm from '../shared/FeedBackForm';
import IntrahubLogo from "../../Assets/IntraHub_Logo.svg";

function AuthCommon({children, isAuthenticated}) {

    return ( 
        <div className='body'>
            <div className='sections'>
            <div className='leftSection'>
                {/* <h2 className="favcyText">Favcy</h2>
                <h2 className='InterhubText'>IntraHub</h2> */}
                <img src={IntrahubLogo} className='leftSectionImage' />
            </div>
            <div className='rightSection'>
                {children}
            </div>
            {
                isAuthenticated ? 
                    <FeedBackForm />
                : null
            }
            </div>
        </div>
     );
}

export default AuthCommon;