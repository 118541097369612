import { url, version } from "../../../Constants/keys";

export async function signIn (obj) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", "Bearer "+obj );
    
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return new Promise((resolve, reject) => {
        fetch(url+version+"users/google", requestOptions)
            .then((response) => response.json())
            .then((response) => {
                resolve(response)
            }).catch(function (e) {
                console.log("ERR", e)
                reject(e)
            })
    });
}

