import { Link, Tooltip, Typography, createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { utils } from '../../../Common/Utills';
const theme = createTheme();

function FormatedTableValue({value}) {

  const [isURL, setIsURL] = useState(false);

  useEffect(()=>{
    checkTextisURL();
  },[])
  
  const checkTextisURL= async () =>{
    let validURL = await utils.isValidUrl(value);
    setIsURL(validURL);
  }
  
  return (
    <Tooltip title={ isURL? (<>
        <Link href={value} color={'#fff'} >
          {value}
        </Link>
    </>) :value} arrow disableInteractive={!isURL}>
      <Typography
      paddingRight={2}
      borderRight={1}
      sx={{
        typography: {
          fontSize: "14px",
          maxWidth: "250px",
          [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
        },
      }}
      borderColor={"#94B1CB"}
      marginY={1}
      noWrap
    >
      {" "}
      {value}{" "}
    </Typography>
    </Tooltip>
  );
}

export default FormatedTableValue;