import React, { useEffect, useState } from "react";
import "../../LookupTypes/LookupTypes.css";
import EditSVG from '../../../../Assets/EditIcon.svg';
import DeleteSVG from '../../../../Assets/DeleteIcon.svg';
import Image from "../../../../Common/FastImage";
import FormatedTableValue from "../../../shared/common/FormatedTableValue";
import { useNavigate } from "react-router-dom";

function FPMTable(props) {
  let { tableData: FPMdata = [] } = props;
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    setTableData(FPMdata);
    setLoading(false);
  },[])

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleEditFPM = (editID) =>{
    if(editID){
      navigate('/dashboard/fpm/'+editID);
    }
  }

  return (
    <div>
      <div
        className="overflow-auto px-3 "
        style={{
          width:
            screenSize.width < 768 ? screenSize.width : screenSize.width - 400,
            height: screenSize.width < 768 ? screenSize.height - 150 : screenSize.height - 220, 
        }}
      >
        <table className="w-full lookupTypeTable">
          <thead className="tableHeader">
            <th> </th>
            <th>Portfolio</th>
            <th>Sponsor</th>
            <th>Master Sheet Name</th>
            <th>Master Sheet No.</th>
            <th>Master Sheet URL</th>
            <th>Version</th>
            <th>Brief Description</th>
            <th>Status</th>
            <th> </th>
          </thead>
          <tbody className="tableBody">
            {loading ? (
              <tr>
                {" "}
                <td className="text-center" colSpan={12}>
                  {" "}
                  Loading...{" "}
                </td>{" "}
              </tr>
            ) : tableData && tableData.length ? (
              tableData.map((item, index) => {

                let portfolioImage = item?.portfolio?.attribute?.find((attri) => {
                  return attri?.code_attribute_name
                    ?.toLowerCase()
                    .toString()
                    .trim()
                    .includes("image");
                });

                return (
                  <tr className="tableBodyRow py-2" key={index}>
                    <td className="p-0 padTop-0">
                        <div className="h-auto md:h-10 p-0 md:p-1 pl-0 md:pl-1 flex" style={{height: '40px', width: '40px'}}>
                            <Image
                                src={portfolioImage?.code_attribute_description}
                                alt="Portfolio Image"
                                className="p-0 ml-2"
                            />
                        </div>
                    </td>
                    <td> 
                        <FormatedTableValue  value={item.portfolio?.lookup_code_name} /> 
                    </td>
                    <td> <FormatedTableValue  value={item.sponsor?.displayName} /> </td>
                    <td> <FormatedTableValue  value={item.master_sheet_name} /> </td>
                    <td> <FormatedTableValue  value={item.master_sheet_no} /> </td>
                    <td> <FormatedTableValue  value={item.master_sheet_url} /> </td>
                    <td> <FormatedTableValue  value={item?.version[0]?.version_name} /> </td>
                    <td> <FormatedTableValue  value={item.mini_brief_description} /> </td>
                    <td> <FormatedTableValue  value={item.status === "0" ? "Drafted" :"Submitted"} /> </td>

                    <td className="actionCol">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          title="Edit"
                          onClick={() => {
                            handleEditFPM(item._id);
                          }}
                        >
                          {" "}
                          <img src={EditSVG} />{" "}
                        </button>
                        <div className="VeritacalSeprator"></div>
                        <button
                          type="button"
                          title="Delete"
                          onClick={() => {
                            // handleDeleteLookup(item._id);
                          }}
                        >
                          {" "}
                          <img src={DeleteSVG} />{" "}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center pt-6 font-bold">
                  {" "}
                  No Data Found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}


export default FPMTable;
