import * as React from "react"
const leave_tracker = (props) => {
  const { color= "#F3F9FF" } = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.92 17.142H1.622V15.17c.077-2.527 5.83-3.03 5.83-3.03.282-.554.393-1.262.428-1.866M11.72 9.946c.034.716.141 1.585.45 2.193 0 0 2.765.242 4.488 1.234"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.835 7.988C7.146 9.8 8.3 11.142 9.669 11.142c1.37 0 2.546-1.373 2.846-3.211.973-1.135-.011-1.977-.011-1.977-.358-1.716-1.512-3.096-2.835-3.096-1.411 0-2.588 1.419-2.858 3.307 0 0-.984.873.027 1.823h-.003ZM18.377 14.323l-3.208 2.38-1.104-1.25"
    />
  </svg>
)}
export default leave_tracker
