import { Route, Routes } from 'react-router-dom';
import './DashboardStyle.css';
import Sidebar from '../Sidebar';
import MenuList from '../Menu';
import Allocation from '../Allocation&Approvals/Allocation';
import ProjectDocuments from '../ProjectDocuments/ProjectDocuments';
import LookupType, { getLookupTypes } from '../LookupTypes/LookupTypes';
import LookupForm from '../LookupTypes/LookupForm/LookupForm';
import DayCommerceClock from '../DayCommerceClock/DayCommerceClock';
import LookupCodes from '../LookupCodes/LookupCodes';
import LookupCodeForm from '../LookupCodes/LookupCodeForm/LookupCodeForm';
import { DatabaseService } from '../../../Services/DatabaseService';
import { useEffect, useState } from 'react';
import FPM from '../FPM_Dashboard';
import TicketDashboard from '../Tickets/TicketDashboard';
import AddTicket from '../Tickets/Components/AddTicket';
import FPMCreation from '../FPM_Dashboard/FPMCreation';
import DashboardBody from '../DashboardBody/DashboradBody';
import AttendanceDashboard from '../AttendanceAdmin/AttendanceDashboard';
import LeaveTracker from '../Leave_Tracker';
import { CircularProgress, createTheme } from '@mui/material';

const theme = createTheme();

function Dashboard(props) {
    let { userProfileData, checkInData } = props;
    const [userMenuData, setUserMenuData] = useState([]);
    const [userData, setUserData] = useState();
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    
    useEffect(()=>{
        getMenuData();
    },[])
    
    useEffect(()=>{
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    },[screenSize]);

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    const getMenuData = async () => {
        let permissions = await getUserPermissions();
        console.log("permissions---------------->",permissions);
        setUserData(permissions.userData);
        if(permissions?.data?.access){
            setUserMenuData(JSON.parse(permissions?.data?.access));
        }
    }

    return ( 
        <>
            <div className='flow-root md:flex dashBoardBody ' id="outer-container" style={{height: screenSize.height+'px'}} >
                {
                    userMenuData ? <>
                        <div className='visible md:invisible'>
                        <Sidebar 
                            pageWrapId={'page-wrap'} 
                            outerContainerId={'outer-container'} 
                            menuData={userMenuData}
                            userProfileData={userProfileData} />
                        </div>
                        <div className="flex-grow-0 h-screen hidden md:block" >
                            <MenuList 
                            menuData={userMenuData}
                            userProfileData={userProfileData} />
                        </div>
                    </> : null
                }
                <div className="flex-grow OutletContainer m-0 md:mr-4 my-0 md:my-5 rounded-3xl p-0 md:p-3 overflow-y-auto" id="page-wrap" 
                    style={{maxHeight: screenSize.height}}>
                        {
                            userData ? 
                            <Routes>
                                <Route index element ={<DashboardBody {...props} />} />
                                <Route index path='day_clock' element ={<DayCommerceClock />} />
                                <Route path='allocation' element ={<Allocation  userProfileData={userProfileData} />} />
                                <Route path='project_docs' element ={<ProjectDocuments />} />
                                <Route path='lookup_type' element ={<LookupType />} loader={() =>{return getLookupTypes}} />
                                <Route path='lookup_type/add' element ={<LookupForm />} />
                                <Route path='lookup_type/:id' element ={<LookupForm />} />
                                <Route path='lookup_code' element ={<LookupCodes />} />
                                <Route path='lookup_code/add' element ={<LookupCodeForm />} />
                                <Route path='lookup_code/:id' element ={<LookupCodeForm />} />
                                <Route path="fpm/" >
                                    <Route index element={<FPM />} />
                                    <Route path='add' element={<FPMCreation />} />
                                    <Route path=':id' element={<FPMCreation />} />
                                </Route>
                                <Route path="tickets/"  >
                                    <Route index element={<TicketDashboard />} />
                                    <Route path='add' element={<AddTicket />} />
                                </Route>
                                <Route path="attendance_admin/"  >
                                    <Route index element={<AttendanceDashboard />} />
                                    <Route path='add' element={<AddTicket />} />
                                </Route>
                                <Route path="leaveTracker/"  >
                                    <Route index element={<LeaveTracker userData={userData} />} />
                                    <Route path='add' element={<LeaveTracker userData={userData}/>} />
                                </Route>
                            </Routes>
                             : <CircularProgress
                            variant="determinate"
                            sx={{
                              color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                            }}
                            size={40}
                            thickness={4}
                            {...props}
                            value={100}
                          />
                        }
                </div>
            </div>
        </>
     );
}

export default Dashboard;

export const getUserPermissions= async () =>{
    return new Promise(async (resolve, reject) => {
        let permissionRes = await DatabaseService('GET', 'users/permissions');
        if(permissionRes.code == 200){
            resolve(permissionRes);
        }
        reject("Not Permissions Provided.");
    });
    return false
}