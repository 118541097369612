import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography, createTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DatabaseService } from "../../Services/DatabaseService";
import SnackBarComponent from "./common/SnackBar";

const theme = createTheme();

export default function FeedBackForm() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [feedbackType, setFeedbackType] = React.useState("issue");
  const [feedbackRemark, setFeedbackRemark] = React.useState("");
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("event-------------------->",event);
    if ( event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSubmit=async()=>{
    console.log("accessing-----");
    if(feedbackRemark.toString().trim() === ""){
      alert('Please enter remark.');
      return
    }

    let feedbackObj = {
      feedback_type: feedbackType,
      feedback_remark: feedbackRemark
    };

    let response = await DatabaseService('POST', 'feedback', feedbackObj);
    if(response.code === 200){
      setState({...state, ['right']:false});
      setFeedbackType("issue");
      setFeedbackRemark("");
      setIsSnackBarOpen(true);
      setTimeout(() => {
        setIsSnackBarOpen(false);
      }, 3000);
    }
  }

  const handleChange = (event) => {
    setFeedbackType(event.target.value);
  };


  return (
    <Box sx={{ position: "absolute", right: "0px", bottom: "120px" }}>
      <React.Fragment key={"right"}>
        <Button
          onClick={toggleDrawer("right", true)}
          sx={{
            backgroundColor: "#838383",
            color: "#fff",
            minWidth: "45px",
            width: "45px",
            height: "250px",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            "&:hover":{
              backgroundColor: "#838383",
              color: "#fff",
              
            },
          }}
        >
          <Typography sx={{ whiteSpace: "nowrap", transform: "rotate(90deg)" }}>
            Report Issue | Suggest
          </Typography>
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          sx={{
            ".MuiModal-backdrop":{
              backgroundColor: 'rgb(0 0 0 / 0%)',

            },
            ".MuiDrawer-paperAnchorRight":{
              bottom: '120px',
              top: 'auto',
              height: "260px",
              width: '480px',
              backgroundColor: '#838383',
              color: '#fff',
              padding: '15px',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
            },
            [theme.breakpoints.down("md")]: {
              ".MuiDrawer-paperAnchorRight":{
                height: "260px",
                width: '100%',
              },
            }
          }}
        >
          <Box className="flex justify-between items-start">
            <Box>
              <Typography sx={styles.mainHeading} >Send Us Your Feedback.</Typography>
              <Typography sx={styles.subHeading}>Contact the Support Team.</Typography>
            </Box>
            <IconButton aria-label="close" size="small">
              <CloseIcon onClick={toggleDrawer("right", false)} sx={{color:"#fff"}} />
            </IconButton>
          </Box>
          <FormControl sx={{mt: 0.5}}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={feedbackType}
              onChange={handleChange}
            >
              <FormControlLabel value="issue" control={<Radio sx={styles.RadioBTN} size="small" />} sx={styles.radioBTNLabel} label="Report an Issue" />
              <FormControlLabel value="suggestion" control={<Radio sx={styles.RadioBTN} size="small" />} sx={styles.radioBTNLabel} label="Give a suggestion" />
            </RadioGroup>
          </FormControl>
          <Box className="w-full bg-white rounded-md">
            <textarea className="w-full rounded-md" placeholder="Add Remarks.." rows={4} 
              value={feedbackRemark}
              onInput={(e)=>{
                setFeedbackRemark(e.target.value);
              }}
              style={{'padding': '10px', color: '#000', outline: 'none', fontSize: '14px'}} />
            <Box  className="flex justify-end">
            <Button className="ml-auto" variant="contained" type="submit" size="small" sx={styles.submitBTN} onClick={()=>handleSubmit()}>
              Submit
            </Button>
            </Box>
          </Box>
        </Drawer>
        {
          isSnackBarOpen ? 
            <SnackBarComponent message={'Feedback submitted.'}  />
          :null
        }
      </React.Fragment>
    </Box>
  );
}

const styles = {
  mainHeading: {
    typography:{
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24.7px',
    },
  },
  subHeading: {
    typography:{
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17.29px',
    },
  },
  RadioBTN:{
      color: '#fff',
      '&.Mui-checked': {
        color: '#fff',
      },
  },
  radioBTNLabel:{
    ".MuiFormControlLabel-label":{
      typography: {
        fontSize: '14px',
        fontWeight: 500
      },
    }
  },
  submitBTN: {
    padding: "2px",
    marginBottom: '5px',
    marginRight: '5px',
    backgroundColor: '#801F52',
    "&:hover":{
      backgroundColor: '#801F52',
    }
  },
}