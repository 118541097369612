import React, { useState } from 'react';
import { Box, Button, ButtonGroup, CircularProgress, Divider } from '@mui/material';
import HolidaysDataTable from './HolidaysDataTable';

const HolidaysSection = ({ permanentHolidays = [], optionalHolidays = [] }) => {
    
    const [activeTab, setActiveTab] = useState("permanent");
    const [tableData, setTableData] = useState(permanentHolidays);
    const [loading, setLoading] = useState(false);

    const style = {
      leftToggleBTN: {
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
        background: activeTab == "permanent" ? "#801F52": "#0000",
        color: activeTab == "permanent" ? "#FFF" : "#801F52",
        border: " 1px solid #801F52 !important",
        width: "120px",
        height: "32px",
        px: 0,
        "& :focus, :hover":{
          background: activeTab == "permanent" ? "#801F52": "#0000",
          color: activeTab == "permanent" ? "#FFF" : "#801F52",
        }
      },
      rightToggleBTN: {
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        background: activeTab == "optional" ? "#801F52" : "#fff0",
        color: activeTab == "optional" ? "#FFF" : "#801F52",
        border: " 1px solid #801F52",
        width: "120px",
        height: "32px",
        px: 0,
        "& :focus, :hover":{
          background: activeTab == "optional" ? "#801F52": "#0000",
          color: activeTab == "optional" ? "#FFF" : "#801F52",
        }
      },
    };

    const onClickHandle = async (value) => {
      console.log("value------------------>",value);
      setActiveTab(value);
      setLoading(true);
      setTimeout(() => {
        if(value === "permanent"){
          setTableData(permanentHolidays);
        }else{
          setTableData(optionalHolidays);
        }
        setLoading(false);
      }, 500);
    }
    
    return (
    <>
    <Box sx={{mx: 2, mt: -5}}>
        <div className="w-full flex justify-end">
        <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
            className="mb-2 rounded-full"
          >
            <Button sx={style.leftToggleBTN} onClick={() => {onClickHandle('permanent')}}>Permanent</Button>
            <Button sx={style.rightToggleBTN} onClick={() => {onClickHandle('optional')}}>Optional</Button>
          </ButtonGroup>
        </div>
        <Divider />
        {
          loading ? 
          <Box className="mx-auto mt-16 flex justify-center" >
            <CircularProgress />
          </Box>
          :
          tableData && tableData.length ?
          <HolidaysDataTable tableData={tableData} />
          :
          null
        }
    </Box>
  </>
)};

export default HolidaysSection;