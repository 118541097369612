import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  createTheme,
} from "@mui/material";
import Image from "../../../../Common/FastImage";
import { useSelector } from "react-redux";
import ticketForm from "../../../../Common/ticketForm.json";
import { Input as BaseInput, inputClasses } from "@mui/base/Input";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const theme = createTheme();

function TicketForm() {
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const isProfileData = useSelector((state) => state?.session?.userData);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedName] = useState();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [formObject, setFormObject] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    createFormData();
  }, []);

  const createFormData = () => {
    let formSchema = ticketForm;
    let indexOfCreatedby = formSchema.findIndex(
      (schema) => schema.variable === "created_by"
    );
    formSchema[indexOfCreatedby].value = isProfileData.displayName;
    let finalSchema = formSchema.map((schema)=> {return schema.type == 'date' ? {...schema, value : dayjs(Date.now())} : schema });
    setFormObject(finalSchema);
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleSubmit = async () => {
    console.log("formFinalObject--------------------->", formObject);

    // setSubmitError(false);
    // setLoading(true);

    // let response = {};
    // if (response.code == 200) {
    //   setLoading(false);
    //   navigate("/dashboard/tickets");
    //   alert("Lookup Type Submitted Successfully.");
    // } else {
    //   setLoading(false);
    //   alert("Please Try After SomeTime.");
    // }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedName(file.name);
    // Additional validation logic
  };

  const handleFormChange = (event, obj) => {
    console.log("change------event--------->",event);
    let changesObjIndex = formObject.findIndex(schema => schema.variable === obj.variable);
    let value = "";
    if(obj.type == "date"){
        value = new Date(event.$d);
    }
    if(obj.type == "dropdown"){
      value = event.target.value;
    }
    setFormObject((prevArr) => {
      const result = [...prevArr];
      result[changesObjIndex].value = value;
      return result;
    });
  };

  const handleCancel = () => {
    navigate("/dashboard/tickets");
  };

  return (
    <>
      <div
        className="bg-white p-4 rounded-xl shadow-md overflow-y-auto pb-6 mt-4"
        style={{
          maxHeight:
            screenSize.width < 768
              ? screenSize.height - 237
              : screenSize.height - 140,
        }}
      >
        <FormGroup>
          <div className="flex gap-2 items-center mb-2">
            <div className="formPortfolioPicSection">
              <Image
                src={"../../../../logo.svg"}
                alt="Portfolio Image"
                className="p-1"
              />
            </div>
            <div>
              <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                <InputLabel id="demo-simple-select-helper-label">
                  Project Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Project Name"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-12 gap-y-2 md:grid-cols-2">
            {formObject
              ? formObject.map((obj, index) => {
                  return (
                    <div className="">
                      <div className="grid gap-1 grid-cols-2 items-center">
                        <div>{obj.label} :</div>
                        <div>
                          {obj.type == "disabled" ? (
                            <Input type="text" disabled value={obj.value} />
                          ) : obj.type == "date" ? (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              {/* <DemoContainer components={["DateTimePicker"]}> */}
                              <DateTimePicker
                                slotProps={{ textField: { size: "small" } }}
                                format="DD/MMM/YYYY hh:mm a"
                                onChange={(e)=>handleFormChange(e, obj)}
                                minDate={dayjs(Date.now())}
                                value={obj.value}
                              />
                              {/* </DemoContainer> */}
                            </LocalizationProvider>
                          ) : obj.type == "dropdown" ? (
                            <FormControl sx={{ width: "100%" }} size="small">
                              <Select id="demo-simple-select-helper" onChange={(e)=>handleFormChange(e, obj)}
                                value={obj.value}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            <Input type={obj.type} placeholder="" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="ticketDetailsFormSection mt-10 mb-4">
            <Textarea
              aria-label="minimum height"
              minRows={1}
              placeholder="Label"
            />
            <Divider sx={{ bgcolor: "#73BDFF" }}></Divider>
            <Textarea
              aria-label="minimum height"
              minRows={3}
              placeholder="Description"
            />
          </div>
          <Typography
            sx={{
              typography: {
                fontSize: 16,
                lineHeight: "19.76px",
                fontWeight: 500,
                color: "#221F4B",
                textAlign: "left",
                [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
              },
            }}
          >
            {"Comments"}
          </Typography>
          <div className="flex gap-2 mt-4 w-full">
            <Avatar src={isProfileData.imageURL} />
            <CommentInput
              placeholder="Add a comment..."
              endAdornment={
                <InputAdornment>
                    <IconButton component="label"  disableRipple disableFocusRipple disableTouchRipple size="small" startIcon={<AttachFileIcon />}>
                      <VisuallyHiddenInput type="file" />
                    </IconButton>

                </InputAdornment>
              }
            />
          </div>

          {submitError ? (
            <p className="text-center text-xs text-red-600">
              All Fields Are Required.
            </p>
          ) : null}
          <div className="flex justify-end pt-4">
            <button
              className="saveBtn"
              type="submit"
              disabled={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              SAVE
            </button>
            <button
              className="cancelBtn"
              type="button"
              onClick={() => {
                handleCancel();
              }}
            >
              CANCEL
            </button>
          </div>
        </FormGroup>
      </div>
    </>
  );
}
export default TicketForm;

const Input = React.forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

const CommentInput = React.forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        input: CommentInputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  );
});

const InputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  padding: 8px 10px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const CommentInputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  padding: 8px 10px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: none;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 2px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  
  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: none;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const InputRoot = styled("div")(
  ({ theme }) => `
  font-family: Poppins, sans-serif;
  width: 100%;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : "#73BDFF"};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  display: flex;
  align-items: center;
  justify-content: center;


  &.${inputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: none;
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const IconButton = styled(Button)(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[700]};
  transform: rotate(45deg);
  `
);

const InputAdornment = styled("div")`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
