import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from "react-redux";
import { signIn } from './GoogleService';
import { login, userData } from '../../../Redux/sessionSlice';
import favcyVBLogo from '../../../Assets/favcyVentureBuilder.svg';
import './GoogleLogin.css';

function GoogleLogin() {


    const dispatch = useDispatch();

    const handleSuccess = async (successRes) =>{
        let response = await signIn(successRes.access_token);
        console.log("response--------signin-------->",response);
        if(response.code == 200 || response.code == 403){
            localStorage.setItem("userToken",response.token);
            dispatch(login(response.token));
            dispatch(userData(response.data));
        }
    }
    
    const Googlelogin =  useGoogleLogin({
        onSuccess: async codeResponse => {
            handleSuccess(codeResponse);
        },
        onError: codeResponse => console.log(codeResponse)
    }); 

    return ( 
        <>
            <div className='cardLogin'>
                {/* <h2 className='loginText'>Login</h2> */}
                <img src={favcyVBLogo} height={56} width={175} className='mx-auto mb-10 mt-4' />
                <button type='button' className='signInButton' onClick={()=>{Googlelogin()}} >
                    <div className='googleImage'></div>
                    Sign in with Google
                </button>
            </div>
        </>
     );
}


export default GoogleLogin;