import { useEffect, useState } from 'react';
import './LookupForm.css';
import PlusSVG from '../../../../Assets/plus.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { DatabaseService } from '../../../../Services/DatabaseService';

function LookupForm () {
    const [attributesList, setAttributesList] = useState([{attribute_name:'',attribute_description:''}]);
    const [lookup_type_name, setLookupTypeName] = useState('');
    const [lookup_type_description, setLookupTypeDescription] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fullLoading, setFullLoading] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    let { id } = useParams(); 

    const navigate = useNavigate();

    useEffect(()=>{
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
    },[screenSize]);

    useEffect(()=>{
        if(id){
            getTypeData();
        }
    },[]);

    const getTypeData = async () =>{
        setFullLoading(true);
        let response = await DatabaseService('GET', 'lookup/type/getBy', null, id);
        if(response.code == 200){
            let data = response.data;
            setLookupTypeName(data.lookup_type_name);
            setLookupTypeDescription(data.lookup_type_description);
            let attributes = data.attribute.map((attribute, index)=>{ return { attribute_name: attribute.attribute_name || '' ,attribute_description: attribute?.attribute_description ||'' } });
            setAttributesList(attributes);
            setFullLoading(false);
        }else{
            alert('Please Try After Sometime.')
            setFullLoading(false);
        }
    }

    function getCurrentDimension(){
        return {
              width: window.innerWidth,
              height: window.innerHeight
        }
    }

    const handleAddButton = () =>{
        let attributeCount = attributesList.length;
        if(attributeCount>9){
            return
        }
        setAttributesList([...attributesList,{attribute_name:'', attribute_description:''}]);
    };

    const handleRemoveAttr = (index) =>{
        let data = attributesList;
        // if(data.length < 2){
        //     return
        // }
        data.splice(0,index);
        setAttributesList(data);
    }

    const handleSubmit = async () =>{
        setSubmitError(false);
        setLoading(true);
        let result = allValuesHaveValue(attributesList);
        if(!result || lookup_type_description.trim().toString() == "" || lookup_type_name.trim().toString() == "" ){
            setSubmitError(true);
            setLoading(false);
            return
        }
        let obj = { 
            "lookup_type_name": lookup_type_name,
            "lookup_type_description": lookup_type_description,
            "attribute" : attributesList
         };
        let response =   id? await DatabaseService('PUT', 'lookup/type', obj, id) : await DatabaseService('POST', 'lookup/type', obj);
        if(response.code == 200){
            setLoading(false);
            navigate('/dashboard/lookup_type');
            alert("Lookup Type Submitted Successfully.");
        }else{
            setLoading(false);
            alert("Please Try After SomeTime.");
        }
    }

    const allValuesHaveValue = (data) => {
        return data.every((item) =>
          Object.values(item).every((value) => typeof value === 'string' && value.trim() !== '')
        );
      };

    const handleCancel = () => {
        navigate('/dashboard/lookup_type')
    }

    return (
        <div>
            {
                fullLoading ? 
                <div className="flex flex-col items-center h-96">
                    <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                    <div className="flex justify-center">
                        <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </div>
                </div>
                :
                <div className="pb-5 pt-14 md:pt-5 px-3">
                    <div className="grid grid-cols-3 gap-4">
                    <div className="">
                        <label className="block text-sm font-medium mb-2">Lookup Type</label>
                        <input type="text" id="input-label1" className="py-3 px-4 block rounded-full text-sm shadow-lg w-full outline-none" 
                        required placeholder="Type" 
                        onChange={(e)=>{
                            setLookupTypeName(e.target.value);
                        }}
                        value={lookup_type_name}
                        />
                    </div>
                    <div className="col-span-2">
                        <label className="block text-sm font-medium mb-2">Description</label>
                        <textarea type="text" id="input-label2" className="py-3 px-4 block rounded-full text-sm shadow-lg w-full outline-none" 
                        required rows={1} placeholder="Description" 
                        onChange={(e)=>{setLookupTypeDescription(e.target.value)}} 
                        value={lookup_type_description}
                        />
                    </div>
                    </div>
                    <div className="pt-4 py-2" >
                        <div className="grid grid-cols-3 gap-4 my-2 px-4 py-3 AttributesHeader shadow-md">
                            <div className="" >Attributes</div>
                            <div className="col-span-2" >Description</div>
                        </div>
                        <div className='attributeSection pt-3' style={{maxHeight: screenSize.width<768 ? screenSize.height-310 :screenSize.height-300}}>
                        {
                                attributesList.map((attribute, index)=>{
                                    return <div className="grid grid-cols-3 gap-4 relative py-2 rounded-full bg-white mb-2 shadow-lg" key={index}>
                                        <div className="">
                                            <input type="text" id={"input-label"+index} className="py-2 px-4 block ml-2 text-sm w-full outline-none" 
                                            required placeholder={"Attribute "+(index+1)} 
                                            onInput={(e) => {
                                                setAttributesList((prevArr) => {
                                                  const result = [...prevArr];
                                                  result[index].attribute_name = e.target.value;
                                                  return result;
                                                });
                                              }}
                                            value={attribute.attribute_name} />
                                        </div>
                                        <div className="col-span-2">
                                            <textarea type="text" id={"input-label"+index} className="py-2 px-4 mr-4 rounded-se-full block text-sm w-full outline-none" 
                                            required rows={1} placeholder={"Description "+(index+1)}  
                                            onInput={(e) => {
                                                setAttributesList((prevArr) => {
                                                  const result = [...prevArr];
                                                  result[index].attribute_description = e.target.value;
                                                  return result;
                                                });
                                              }}
                                            value={attribute.attribute_description}
                                            />
                                        </div>
                                        {/* <button className='crossButton absolute right-2 -top-3' onClick={()=>{handleRemoveAttr(index)}} >x</button> */}
                                    </div>
                                })
                        }
                        </div>
                    </div>
                    {submitError ?
                    <p className='text-center text-xs text-red-600' >All Fields Are Required.</p>
                    : null}
                    <button className="addAttributeBtn" type='button' onClick={()=>{handleAddButton()}}>
                            <img src={PlusSVG} />
                    </button>
                    <div className='flex absolute bottom-10 right-12'>
                        <button className="saveBtn" type='submit' disabled={loading} onClick={()=>{handleSubmit()}}>
                                SAVE
                        </button>
                        <button className="cancelBtn" type='button' onClick={()=>{handleCancel()}}>
                                CANCEL
                        </button>
                    </div>
                </div>

            }

        </div>
    )
}
export default LookupForm;