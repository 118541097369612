import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {DialogTitle, DialogContent, DialogActions, Button, TextField} from '@mui/material';

const RemarkDialog = ({openRemark, handleRemarkClose, data}) => {
    const [open, setOpen] = useState(openRemark);
    const [remarkInput, setRemarkInput] = useState("");

    // Update the dialog open state when the prop changes
    useEffect(() => {
        setOpen(openRemark);
        setRemarkInput(data.remark);
    }, [openRemark]);

    return (
        <Dialog
            open={open}
            onClose={() => handleRemarkClose(remarkInput, false)}
            maxWidth={'md'}
        >
            <DialogTitle>Remark</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    sx={{width: '50vw'}}
                    value={remarkInput}
                    onChange={(event) => setRemarkInput(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemarkClose}>Cancel</Button>
                <Button onClick={() => handleRemarkClose(remarkInput, true)}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemarkDialog;
