import React, { useEffect, useState } from "react";
import './FPMDashboard.css';
import { DatabaseService } from "../../../Services/DatabaseService";
import HeaderSearch from "../../shared/HeaderSearch";
import { Box, CircularProgress, Divider, IconButton, Typography, createTheme } from "@mui/material";
import FPMTable from "./components/FPMTable";
import Image from "../../../Common/FastImage";
import EditSVG from '../../../Assets/EditIcon.svg';
import DeleteSVG from '../../../Assets/DeleteIcon.svg';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useNavigate } from "react-router-dom";

const theme = createTheme();

function FPM() {
  const [FPMData, setFPMData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [expandedSection, setExpandedSection] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    GetData();
    return () => {};
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleCardExpand = async(id) =>{
    if(id === expandedSection){
      setExpandedSection("");
    }else{
      setExpandedSection(id);
    }
  }

  const handleEditFPM = (editID) =>{
    if(editID){
      navigate('/dashboard/fpm/'+editID);
    }
  }

  const GetData = async () => {
    setIsLoading(true);
    let response = await getFPMData();
    if (response.code === 200) {
      setFPMData(response.data);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <HeaderSearch />
      <Typography
        className="px-3 py-1 md:py-3"
        sx={{
          typography: {
            fontSize: 22,
            [theme.breakpoints.down("md")]: { fontSize: "14px" },
            fontWeight: 700,
            color: "#221F4B",
            lineHeight: "27.17px",
          },
        }}
      >
        FPM Dashboard
      </Typography>
      {isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: screenSize.height - 200,
            }}
          >
            <CircularProgress />
            Loading...
          </Box>
        </>
      ) : (
        <div>
          <div className="hidden md:block">
            <FPMTable tableData={FPMData} />
          </div>
          <div className="block md:hidden">
              {
                FPMData.map((data)=>{
                  let portfolioImage = data?.portfolio?.attribute?.find((attri) => {
                    return attri?.code_attribute_name
                      ?.toLowerCase()
                      .toString()
                      .trim()
                      .includes("image");
                  });
                  return (
                    <Box className={expandedSection=== data._id ? 'scale-in-top' : ''}
                      sx={{mx: 1, mb: 2, px:1, pt: 2, pb: '4px', background: '#fff', borderRadius: '10px', boxShadow: '4px 4px 40px 0px #00000014', typography:{fontSize: '14px'}}}>
                        <div className="flex w-full items-center">
                          <Box className="flex grow items-center" sx={{bgcolor: '#94B1CB', mr: 1, borderRadius: '100px', minHeight: '40px'}}>
                            <Typography className="grow-0 px-4" sx={styles.commonFont} >Portfolio</Typography>
                            <Box className="flex grow gap-1 items-center" sx={{bgcolor: '#03467D', borderRadius: '100px', minHeight: '30px', mr:'4px'}}>
                              <Box sx={{ml: "4px",height: '22px', width: '22px', borderRadius: '100px', bgcolor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                                <Image src={portfolioImage?.code_attribute_description}  alt="" style={{width: '17px', height: '17px'}} />
                              </Box>
                              <Typography className="text-white pl-2" noWrap sx={{...styles.commonFont, maxWidth: screenSize.width-260+'px' }}>{data?.portfolio?.lookup_code_name} </Typography>
                            </Box>
                          </Box>
                            <div className="grow-0">
                              <Typography className="text-white px-3 py-1 rounded-3xl" sx={{...styles.commonFont, bgcolor: '#0485E1'}}>{data.status === "1" ? 'Submitted': 'Drafted'}</Typography>
                            </div>
                        </div>
                        {
                          expandedSection === data._id ? 
                            <Box sx={{bgcolor: '#94B1CB', borderRadius: '20px', my: 1}} className="flex justify-center flex-col scale-in-top" >
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading , borderTopLeftRadius: '20px'}} >Sponsor</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue , xl: 1, py: 1}} >{data.sponsor.displayName}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading }} >FPM ID</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data._id}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading }} >FPM Description</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data.description}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading }} >Master sheet name</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data.master_sheet_name}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading}} >Master sheet no.</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data.master_sheet_no}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading}} >Master sheet URL</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, pl: 1, py: 1}} >{data.master_sheet_url}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading}} >Version</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data?.version[0]?.version_name}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading , borderBottomLeftRadius: '20px'}} >Brief Description</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data?.mini_brief_description}</Typography>
                              </Box>
                            </Box>
                          :
                          <Box sx={{bgcolor: '#94B1CB', borderRadius: '20px', my: 1}} className="flex justify-center flex-col" >
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading , borderTopLeftRadius: '20px'}} >Sponsor</Typography>
                                <Typography sx={{...styles.commonFont, ...styles.dataValue , pl: 1, py: 1}} >{data.sponsor.displayName}</Typography>
                              </Box>
                              <div className="px-3">
                                <Divider sx={{ bgcolor: '#fff'}} />
                              </div>
                              <Box className="grid grid-cols-2 gap-2">
                                <Typography sx={{...styles.commonFont, ...styles.dataHeading , borderBottomLeftRadius: '20px'}} >FPM ID</Typography>
                                <Typography noWrap sx={{...styles.commonFont, ...styles.dataValue, px: 1, py: 1}} >{data._id}</Typography>
                              </Box>
                          </Box>

                        }
                     
                        <Box className="flex justify-between">
                          <div className='flex items-center'>
                              <button type='button' title='Edit' onClick={()=>{
                                    handleEditFPM(data._id)
                                }} > <img src={EditSVG} /> </button>
                              <div className='VeritacalSeprator' ></div>
                              <button type='button' title='Delete' onClick={()=>{
                                // handleDeleteLookup(item._id)
                                }}  > <img src={DeleteSVG} /> </button>
                          </div>
                          <IconButton sx={{color: '#221F4B'}} className={ expandedSection === data._id ? "rotate-in-2-cw": ""} onClick={()=>{
                            handleCardExpand(data._id)
                          }} >
                            {
                              expandedSection === data._id ? 
                              <KeyboardDoubleArrowUpIcon  color="#221F4B" />
                              :
                              <KeyboardDoubleArrowDownIcon color="#221F4B" />
                            }
                          </IconButton>
                        </Box>
                    </Box>
                  )
                })
              }
              
          </div>
        </div>
      )}
    </div>
  );
}

export default FPM;

const getFPMData = async () => {
  let response = await DatabaseService("GET", "fpm");
  return response;
};

const styles = {
  commonFont: {fontSize: '14px', fontWeight: 600},
  dataHeading: {
    pl: 2, py: 1, bgcolor: '#03467D', color: '#fff'
  },
  dataValue: {
    color: '#221F4B',
  }
}