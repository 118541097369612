import './Allocation.css';
import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import searchSVG from '../../../Assets/search.svg';
import bellSVG from '../../../Assets/bell.svg';
import sendSVG from '../../../Assets/sendIcon.svg';
import { url } from '../../../Constants/keys';
import { DatabaseService } from '../../../Services/DatabaseService';
import attachIcon from '../../../Assets/attactPlusIcon.svg';
import dummy1 from '../../../Assets/dummyImages/Ellipse_40.png';
import dummy2 from '../../../Assets/dummyImages/Ellipse_41.png';
import dummy3 from '../../../Assets/dummyImages/Ellipse_42.png';
import dummy4 from '../../../Assets/dummyImages/Ellipse_43.png';
import dummy5 from '../../../Assets/dummyImages/Ellipse_45.png';

const Allocation =({userProfileData}) => {

    const socket = io(url);
    const [chat, setChat] = useState();
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [message, setMessage] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const bottomRef = useRef(null);

    const handleSendMessage = (e) => {
      e.preventDefault();
      console.log({ userName: localStorage.getItem('userName'), message });
      socket.emit('message',{
        token: localStorage.getItem('userToken'),
        message: message
      })
      setMessage('');
    };

    function getCurrentDimension(){
      return {
            width: window.innerWidth,
            height: window.innerHeight
      }
    }

    const tok = localStorage.getItem('userToken');

    useEffect(() => {
        // Connect to the Socket.io server when the component mounts
        socket.connect();
        socket.emit('new-user-joined', localStorage.getItem('userToken'));

        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        socket.on('user-connected', (message) => {
            console.log("userConnect---------------->",message)
        // setMessages((prevMessages) => [...prevMessages, message]);
        });
        
        socket.on('messages', (message) => {
            getChatData();
        });

        socket.on('show-approval', (message) => {
            getChatData();
        });

        socket.on('partner-approval-data', (message) => {
            console.log("partner-approval00000000000------->",message);
            getChatData();
        });

        socket.on('not-connect',(message)=>{
            console.log("not_connect_message---------------------->",message);
        });
        
        if(userProfileData){
            setUserDetails(userProfileData)
        }
        
        getChatData(); 

       
    }, [userProfileData]);

    useEffect(()=>{
             // Disconnect from the Socket.io server when the component unmounts
        return () => {
            console.log("returning_calllll");
            // socket.emit('user-not-connect',tok);
            // socket.disconnect();
        };  
    },[])

    useEffect(()=>{
        bottomRef.current?.scrollIntoView({behavior: 'instant'});
    },[chat])

    const getChatData = async () =>{
        let chatsRes = await DatabaseService('GET', 'chats');
        console.log("chats----------------------->",chatsRes.data)
        if(chatsRes.code == 200){
            setChat(chatsRes.data);
        }
    }

    const formateDate=(date)=>{
        
        let today = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        if(today.getDate() == new Date(date).getDate()){
            return 'Today';
        }else{
            let day = new Date(date).getDate();
            let month = monthNames[new Date(date).getMonth()];
            return month + ', ' + day ;
        }
    }

    const formateTime=(date)=>{
        const currentDate = new Date(date);
        let hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour time to 12-hour time
        if (hours > 12) {
        hours -= 12;
        }

        // Ensure two digits for hours and minutes with leading zero if needed
        hours = String(hours).padStart(2, '0');
        return `${hours}:${String(minutes).padStart(2, '0')} ${amOrPm}`;
    }

    const handleApprovalCheck = (id, action) =>{
        socket.emit('approval-show', id , action);
    }

    const handlePartnerApproval = (id, partnerId) => {
        socket.emit('partner-approval', id, partnerId);
    }

    return (
        <>
            <div className="chatScreen">
                {/* chat header */}
                <div className="chatHeader my-1 md:my-3" >
                <div className="w-full rounded-full flex px-3">
                <div className="hidden md:flex avatar-row flex-grow-0">
                    <div className="avatar">
                        <img src={dummy1} alt="Avatar 1" />
                    </div>
                    <div className="avatar">
                        <img src={dummy2} alt="Avatar 2" />
                    </div>
                    <div className="avatar">
                        <img src={dummy3} alt="Avatar 3" />
                    </div>
                    <div className="avatar">
                        <img src={dummy4} alt="Avatar 4" />
                    </div>
                    <div className="avatar">
                        <img src={dummy5} alt="Avatar 5" />
                    </div>
                </div>
                    <div className=" flex justify-center items-center flex-grow ml-9 md:ml-0 mr-2">
                        <button disabled type='reset' className="flex w-10 items-center justify-center leftSearchIcon z-10">
                            <img src={searchSVG} />
                        </button>
                        <input type="text"  className="w-full  -ml-4 h-8 pl-6 text-base font-semibold outline-0 rounded-r-3xl searchInput" placeholder="" id="" />
                        {/* <input type="button" value="Buscar" className="bg-blue-500 p-2 rounded-tr-lg rounded-br-lg text-white font-semibold hover:bg-blue-800 transition-colors"/> */}
                    </div>
                    <div className="flex-grow-0" >
                        <img src={userDetails.imageURL} className="headerProfileImage" />
                    </div>
                    <div className="flex-grow-0 pl-2" >
                    <button className="notificationBtn" type='reset'>
                        <img src={bellSVG} />
                    </button>
                    </div>
                </div>
                </div>
                {/* chat messages section */}
                <div className="chatMessages ml-1 mr-0 md:ml-6 md:mx-6" style={{height: screenSize.width<768 ? screenSize.height-117 :screenSize.height-190}}>
                    <div className="message__container">
                        {
                            chat && chat.length ?
                                chat.map((msgObj)=>{
                                    return (
                                        <div>
                                            <div className='message_chats flex justify-center'>
                                                <div className='message_chats_date'>{formateDate(msgObj.date)}</div>
                                            </div>
                                            {
                                                msgObj.value.length ?
                                                    msgObj.value.map((msg)=>{
                                                        return (                                                            
                                                            msg.userGoogleId === userDetails.googleId ? 
                                                            <div className="message__chats">
                                                                {/*This shows messages sent from you*/}
                                                                <div className="message__sender">
                                                                    <div className='flex items-baseline'>
                                                                        <div>
                                                                            <p className="sender__name"> </p>
                                                                            <p className='sender_message'>{msg.message}</p>
                                                                        </div>
                                                                        <div className=''>
                                                                            {
                                                                                msg.readyForApproval == 1 || msg.readyForApproval == 2 ? 
                                                                                    <input type='checkbox' className='ml-3' disabled={msg.readyForApproval === 2}  checked={msg.readyForApproval === 2} onClick={()=>{handleApprovalCheck(msg._id, 2)}} />
                                                                                :  null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <p className='recipient_time' >{formateTime(msg.creationDate)}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="message__chats flex">
                                                                {/*This shows messages received by you*/}
                                                                <div className='profile_pic_section'>
                                                                    <img className='profile_pic' src={msg.user.imageURL} />
                                                                </div>
                                                                <div className="message__recipient">
                                                                    <p className='recipient_name' >{msg.user.firstName}</p>
                                                                    <div className='flex items-baseline'>
                                                                        <div>
                                                                            <p className='recipient_message'>{msg.message}</p>
                                                                        </div>
                                                                        <div className=''>
                                                                            {
                                                                                msg.readyForApproval == 2 ? 
                                                                                    <input type='checkbox' className='ml-3' disabled={msg.readyForApproval === 2}  checked={msg.readyForApproval === 2} onClick={()=>{handleApprovalCheck(msg._id, 2)}} />
                                                                                :  null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        msg.readyForApproval == 2 ? 
                                                                            <input type='checkbox' className='ml-3'  onClick={()=>{handlePartnerApproval(msg._id, msg.userGoogleId)}} />
                                                                        :  null
                                                                    }
                                                                    <p className='recipient_time' >{formateTime(msg.creationDate)}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : <></>
                                            }
                                            <div ref={bottomRef} />
                                        </div>

                                    )
                                })
                            :
                            <div className='message_chats flex justify-center'>
                                <div className='message_chats_date'> No Messages.</div>
                            </div>

                        }

                        {/*This is triggered when a user is typing*/}
                        {/* <div className="message__status">
                        <p>Someone is typing...</p>
                        </div> */}
                    </div>
                </div>
                {/* chat footer form */}
                <div className="chatFooter" >

                <form className="w-full rounded-full flex px-3 md:px-2 py-0" onSubmit={handleSendMessage}>
                   
                    <div className=" flex justify-center items-center flex-grow mr-0 md:mr-2">
                        <input type="text"  
                        className="w-full  -ml-4 h-8 pl-4 md:pl-6 pr-12 text-base font-semibold outline-0 messageInput" placeholder="Message" id=""
                        value={message}
                        onChange={(e) => setMessage(e.target.value)} />
                        <button type='button' className="-ml-10 rounded-tr-lg rounded-br-lg transition-colors" >
                            <img src={attachIcon} />
                        </button>
                    </div>
                    <div className="flex-grow-0" >
                    <button className="sendBtn" type='submit'>
                        <img src={sendSVG} />
                    </button>
                    </div>
                </form>

                </div>
            </div>
        </>
    )
};

export default Allocation;