import React, { useEffect, useState } from "react";
import SearchLessHeader from "../../shared/SearchLessHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  createTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "@mui/icons-material";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import { DatabaseService } from "../../../Services/DatabaseService";
import Image from "../../../Common/FastImage";
import SnackBarComponent from "../../shared/common/SnackBar";
import PickerWithBrowserField from "./components/DataPicker";
import dayjs from "dayjs";

const theme = createTheme();

const masterSheet = {
  master_sheet_name: "",
  master_sheet_no: "",
  master_sheet_url: "",
};
const variant = {
  version_name: "",
  version_description: "",
  version_author: "",
  version_date: dayjs(Date.now()),
};
const PID = { scope: "", milestones: "", efforts: "" };
const MPP = {
  mini_brief_description: "",
  mini_key_objectives: "",
  mini_estimated_budget: "",
  mini_estimated_timeline: "",
};
const SBC = {
  problem_opportunity: "",
  const_beneifit: "",
  estimeted_consts: "",
  estimeted_beneifits: "",
  roi: "",
  estimated_roi_percentage: "",
};
const KPI = { kpi_scope: "", current_status: "", target: "", actual: "" };
const SRL = { objectives_met: "", next_steps: "" };
const deliverable = { deliverable: "" };
const PCL = {
  budget_timeline: "",
  were_they_met: "",
  remarks: "",
  one_lesson_learned: "",
};

function FPMCreation() {
  const [portfolioImage, setPortfolioImage] = useState("");

  // Form Data Variables
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [selectedSponser, setSelectedSponser] = useState("");
  const [description, setDescription] = useState("");
  const [masterSheetData, setMasterSheetData] = useState(masterSheet);
  const [variantsList, setVariantsList] = useState([
    {
      version_name: "",
      version_description: "",
      version_author: "",
      version_date: dayjs(Date.now()),
    },
  ]);
  const [PIDList, setPIDList] = useState([PID]);
  const [key_stakeholders, setkey_stakeholders] = useState("");
  const [MPPData, setMPPData] = useState(MPP);
  const [SBCData, setSBCData] = useState(SBC);
  const [KPIList, setKPIList] = useState([KPI]);
  const [SRLList, setSRLList] = useState([SRL]);
  const [deliverablesList, setDeliverablesList] = useState([
    { deliverable: "" },
  ]);
  const [PCLData, setPCLData] = useState(PCL);
  const [finalSubmit, setFinalSubmit] = useState(0);

  // Required Variables
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [today, setToday] = useState(new Date());
  const [isLoading, setisLoading] = useState(true);
  const [isSubmitLoading, setisSubmitLoading] = useState(false);

  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [lookupCodeList, setLookupCodeList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  let { id } = useParams(); 

  const navigate = useNavigate();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  useEffect(() => {
    getInitialFormatedDate();
    getRequiredList();
  }, []);

  const getInitialFormatedDate = async () => {
    let today = new Date();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    setToday(year + "-" + month + "-" + date);
    setVariantsList((prev) => {
      let result = variantsList;
      result[0].date = year + "-" + month + "-" + date;
      return result;
    });
    return year + "-" + month + "-" + date;
  };

  const getFormatedDate = async (value) => {
    let today = new Date(value);
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    return year + "-" + month + "-" + date;
  };

  const getRequiredList = async () => {
    let lookupData = await getLookupList();
    let userData = await getAllUsers();
    if(id){
      getFPMData()
    }else{
      setisLoading(false);
    }
  };

  const getFPMData = async () => {
    let formData = await DatabaseService('GET', 'fpm', null, id);
    if(formData.code === 200){
      console.log("formData---------------------------------->",formData.data);
      let responseData = formData.data;
      
      let imageAttribute = responseData.portfolio.attribute?.find((attri) => {
        return attri?.code_attribute_name
          ?.toLowerCase()
          .toString()
          .trim()
          .includes("image");
      });

      if(imageAttribute._id){
        setPortfolioImage(imageAttribute.code_attribute_description)
      }
      setDescription(responseData.description || "");
      setSelectedPortfolio(responseData.portfolio._id);
      setSelectedSponser(responseData.sponsor._id);
      setMasterSheetData({
        master_sheet_name : responseData.master_sheet_name,
        master_sheet_no: responseData.master_sheet_no,
        master_sheet_url: responseData.master_sheet_url
      });
      setMPPData({
        mini_brief_description: responseData.mini_brief_description,
        mini_key_objectives: responseData.mini_key_objectives,
        mini_estimated_budget: responseData.mini_estimated_budget,
        mini_estimated_timeline: responseData.mini_estimated_timeline
      });
      setkey_stakeholders(responseData.key_stakeholders);
      setSBCData({
        problem_opportunity: responseData.problem_opportunity,
        const_beneifit: responseData.const_beneifit,
        estimeted_beneifits: responseData.estimeted_beneifits,
        estimeted_consts: responseData.estimeted_consts,
        roi: responseData.roi,
        estimated_roi_percentage: responseData.estimated_roi_percentage
      });
      setPCLData({
       budget_timeline: responseData.budget_timeline,
       one_lesson_learned: responseData.one_lesson_learned,
       remarks: responseData.remarks,
       were_they_met: responseData.were_they_met
      });
      setFinalSubmit(responseData.status);
      setVariantsList(responseData.version);
      setPIDList(responseData.pid);
      setKPIList(responseData.kpi);
      setSRLList(responseData.stagereview);
      setDeliverablesList(responseData.deliverable);
      setisLoading(false);
    }else{
      navigate('/dashboard/fpm');
    }
  }

  const getLookupList = async () => {
    let lookupRes = await DatabaseService("GET", "lookup/code");
    if (lookupRes.code === 200) {
      let portfolios = lookupRes.data.filter((lookupCode, index)=>{ return lookupCode.lookupType.lookup_type_name.trim().toLowerCase() === "portfolio"}) || [];
      setLookupCodeList(portfolios);
      return lookupRes.data;
    }
  };

  const getAllUsers = async () => {
    let userResponse = await DatabaseService("GET", "users");
    if (userResponse.code === 200) {
      setUsersList(userResponse.data);
      return userResponse.data;
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const handleAddVariant = () => {
    setVariantsList((state) => {
      return [...state, { ...variant, date: today }];
    });
  };

  const handleAddPID = () => {
    setPIDList((state) => {
      return [...state, { scope: "", milestones: "", efforts: "" }];
    });
  };

  const handleAddKPI = () => {
    setKPIList((state) => {
      return [
        ...state,
        { kpi_scope: "", current_status: "", target: "", actual: "" },
      ];
    });
  };

  const handleAddSRL = () => {
    setSRLList((state) => {
      return [...state, { objectives_met: "", next_steps: "" }];
    });
  };

  const handleAddDeliverables = () => {
    setDeliverablesList((state) => {
      return [...state, { deliverable: "" }];
    });
  };

  const handlePortfolioChange = async (code) => {
    setPortfolioImage("");
    let value = code.target.value;
    setSelectedPortfolio(code.target.value);
    let indexValue = await lookupCodeList?.findIndex(
      (code) => code?._id === value
    );
    let codeData = await lookupCodeList[indexValue];
    if (codeData.attribute) {
      let imageAttrilist = codeData?.attribute?.find((attri) => {
        return attri?.code_attribute_name
          ?.toLowerCase()
          .toString()
          .trim()
          .includes("image");
      });
      if (imageAttrilist?._id) {
        setPortfolioImage(imageAttrilist.code_attribute_description);
      }
    }
  };

  const handleWereTheyMetChange = async (e) => {
    setPCLData((prev) => {
      return { ...prev, were_they_met: e.target.value };
    });
  };

  const handleDraftSave = async () => {
    setisSubmitLoading(true);
    let finalData = {
      portfolio: selectedPortfolio,
      sponsor: selectedSponser,
      ...masterSheetData,
      ...MPPData,
      key_stakeholders: key_stakeholders,
      ...SBCData,
      ...PCLData,
      status: finalSubmit,
      version: variantsList,
      pid: PIDList,
      kpi: KPIList,
      stagereview: SRLList,
      deliverable: deliverablesList,
    };
    console.log("FinalData--------------------------->", finalData);
    let response = id? await DatabaseService("PUT", "fpm", finalData, id) : await DatabaseService("POST", "fpm", finalData);
    if (response.code === 200) {
      setSnackBarShow(true);
      navigate("/dashboard/fpm");
    } else {
      setSnackBarShow(true);
      setSnackBarMessage(response.message);
      setTimeout(() => {
        setSnackBarShow(false);
        setSnackBarMessage("");
      }, 2000);
    }
    setisSubmitLoading(false);
  };

  return (
    <>
      <SearchLessHeader />
      {isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: screenSize.height - 200,
            }}
          >
            <CircularProgress />
            Loading...
          </Box>
        </>
      ) : (
        <>
          <div
            className="pb-3 px-3 overflow-y-auto"
            style={{
              maxHeight:
                screenSize.width < 768
                  ? screenSize.height - 137
                  : screenSize.height - 130,
            }}
          >
            {/* portfolio details section */}
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#801F52",
                borderRadius: "10px",
                paddingY: 4,
                paddingX: 3,
                [theme.breakpoints.down("md")]: {
                  paddingY: 2,
                  paddingX: 2,
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    Portfolio
                  </Typography>
                  <FormControl
                    variant="standard"
                    className="flex-grow my-0 md:my-2"
                  >
                    <Select
                      disableUnderline
                      sx={FormStyles.portfolioSelect}
                      startAdornment={
                        <Box
                          sx={FormStyles.selectStartIcon}
                          style={{
                            background: portfolioImage
                              ? "transparent"
                              : "#FF914E",
                          }}
                        >
                          {portfolioImage ? (
                            <Image
                              alt="Logo"
                              src={portfolioImage}
                              style={{ height: "25px", width: "25px" }}
                            />
                          ) : (
                            <AddToPhotosOutlinedIcon
                              sx={{
                                transform: "rotate(180deg)",
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </Box>
                      }
                      onChange={(e) => {
                        handlePortfolioChange(e);
                      }}
                      value={selectedPortfolio}
                    >
                      <MenuItem aria-label="None" value="" />
                      {lookupCodeList && lookupCodeList.length
                        ? lookupCodeList.map((code) => {
                            return (
                              <MenuItem value={code._id}>
                                {code.lookup_code_name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    Sponsor
                  </Typography>
                  <FormControl
                    variant="standard"
                    className="flex-grow my-0 md:my-2"
                  >
                    <Select
                      disableUnderline
                      sx={FormStyles.portfolioSelect}
                      onChange={(e) => {
                        setSelectedSponser(e.target.value);
                      }}
                      value={selectedSponser}
                    >
                      <MenuItem aria-label="None" value="">
                        {"Select Sponsor"}
                      </MenuItem>
                      {usersList && usersList.length
                        ? usersList.map((user) => {
                            return (
                              <MenuItem value={user._id}>
                                {user.displayName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    FPM ID
                  </Typography>
                  <InputBase
                    sx={FormStyles.portfolioInput}
                    disabled
                    placeholder="Auto Generate"
                    value={id || 'Auto Generate'}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    FPM Description
                  </Typography>
                  <InputBase
                    sx={FormStyles.portfolioInput}
                    onChange={(e) => {
                      setDescription((prev) => {
                        return e.target.value 
                      });
                    }}
                    value={description}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    Master Sheet Name
                  </Typography>
                  <InputBase
                    sx={FormStyles.portfolioInput}
                    onChange={(e) => {
                      setMasterSheetData((prev) => {
                        return { ...prev, master_sheet_name: e.target.value };
                      });
                    }}
                    value={masterSheetData.master_sheet_name}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    Master Sheet No
                  </Typography>
                  <InputBase
                    sx={FormStyles.portfolioInput}
                    onChange={(e) => {
                      setMasterSheetData((prev) => {
                        return { ...prev, master_sheet_no: e.target.value };
                      });
                    }}
                    value={masterSheetData.master_sheet_no}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={FormStyles.portfolioGridForm}
                >
                  <Typography sx={FormStyles.portfolioLabels}>
                    Master Sheet URL
                  </Typography>
                  <InputBase
                    sx={FormStyles.portfolioInput}
                    startAdornment={
                      <Box sx={FormStyles.InputStartIcon}>
                        <Link />
                      </Box>
                    }
                    onChange={(e) => {
                      setMasterSheetData((prev) => {
                        return { ...prev, master_sheet_url: e.target.value };
                      });
                    }}
                    value={masterSheetData.master_sheet_url}
                  />
                </Grid>
              </Grid>
              <div className=" hidden md:block">
                <div
                  container
                  className="grid grid-cols-4 gap-4 my-2 pr-4 pl-10 py-3 fpmPortfolioVesionHeader  "
                >
                  <Typography sx={FormStyles.varientHeaderText}>
                    Version
                  </Typography>
                  <Typography sx={FormStyles.varientHeaderText}>
                    Description
                  </Typography>
                  <Typography sx={FormStyles.varientHeaderText}>
                    Author
                  </Typography>
                  <Typography sx={FormStyles.varientHeaderText}>
                    Date
                  </Typography>
                </div>
                <div className="pt-0">
                  {variantsList.map((variant, index) => {
                    return (
                      <Box
                        className="grid grid-cols-4 relative items-center px-4 rounded-full bg-white mb-2"
                        sx={{
                          minHeight: "40px",
                          boxShadow: "6px 4px 30px 0px #0000000D",
                        }}
                        key={index}
                      >
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 block ml-2 text-sm w-full outline-none mr-2"
                            required
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_name = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_name}
                          />
                        </div>
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <textarea
                            type="text"
                            id={"input-label" + index}
                            className=" px-3 ml-2 mr-4 rounded-se-full block text-sm w-full outline-none"
                            required
                            style={FormStyles.inputBox}
                            rows={1}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_description = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_description}
                          />
                        </div>
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 block ml-2 text-sm w-full outline-none mr-2"
                            required
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_author = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_author}
                          />
                        </div>
                        <div className="flex">
                          {/* <input
                            type="date"
                            id={"input-label" + index}
                            className=" px-4 block ml-2 text-sm outline-none"
                            min={today}
                            required
                            placeholder={"date"}
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                console.log(
                                  "data_value----------->",
                                  e.target.value
                                );
                                const result = [...prevArr];
                                result[index].version_date = e.target.value;
                                return result;
                              });
                            }}
                            defaultValue={today}
                            value={getFormatedDate(variant.version_date)}
                          /> */}
                            {/* <FormControl sx={{ m: 0, border: 'none' }} size="small">
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer
                                      components={[
                                        'DatePicker'
                                      ]}
                                      sx={{border: 'none', padding: 0}}
                                    >
                                        <DatePicker label={""} disableUnderline  
                                        slotProps={{p:0,toolbar:{p:0, fontSize: '12px'}, textField: {size: "small", variant:'standard', typography: {
                                          fontSize: '14px',
                                        }}}}  
                                        sx={{px:0, mx: 1, border: "none", textField:{fontSize: '14px'}}} />
                                    </DemoContainer>
                                  </LocalizationProvider>
                          </FormControl> */}

                          <PickerWithBrowserField value={variant.version_date} />
                        </div>
                        <button
                          className=" absolute right-3 top-2"
                          onClick={() => {
                            if (variantsList.length === 1) {
                              return false;
                            }
                            setVariantsList((prevArr) => {
                              const result = [...prevArr];
                              result.splice(index, 1);
                              return result;
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            sx={FormStyles.DeleteButtonIcon}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>
              </div>
              <div className=" block md:hidden pt-2">
                {variantsList.map((variant, index) => {
                  return (
                    <>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 my-2 pr-4   rounded-full items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-3 md:pl-10 py-2 rounded-l-full col-span-2"
                          sx={FormStyles.portfolioVersionLabel}
                        >
                          Version
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label"}
                            className=" px-1 md:px-4 block mr-0 md:mr-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_name = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_name}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 my-2 pr-4   rounded-full items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-3 md:pl-10 py-2 rounded-l-full col-span-2"
                          sx={FormStyles.portfolioVersionLabel}
                        >
                          Description
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label"}
                            className=" px-1 md:px-4 block mr-0 md:mr-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_description = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_description}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 my-2 pr-4   rounded-full items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-3 md:pl-10 py-2 rounded-l-full col-span-2"
                          sx={FormStyles.portfolioVersionLabel}
                        >
                          Author
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label"}
                            className=" px-1 md:px-4 block mr-0 md:mr-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_author = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_author}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 my-2 pr-4   rounded-full items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-3 md:pl-10 py-2 rounded-l-full col-span-2"
                          sx={FormStyles.portfolioVersionLabel}
                        >
                          Date
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="date"
                            id={"input-label" + index}
                            className=" px-1 block w-full text-sm outline-none"
                            min={today}
                            required
                            placeholder={"date"}
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setVariantsList((prevArr) => {
                                const result = [...prevArr];
                                result[index].version_date = e.target.value;
                                return result;
                              });
                            }}
                            value={variant.version_date}
                          />
                        </div>
                      </Box>
                    </>
                  );
                })}
              </div>
              <Button
                type="button"
                sx={FormStyles.variantAddButton}
                variant="contained"
                onClick={() => {
                  handleAddVariant();
                }}
              >
                <AddIcon
                  sx={{ color: "#221F4B", fontWeight: 700, fontSize: "30px" }}
                />
              </Button>
            </Paper>

            {/* Mini Project Proposal Section */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Mini Project Proposal
              </Typography>
              <div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 my-2 pr-4 pl-4 md:pl-10 py-2 AttributesHeader  ">
                  <Typography
                    className="col-span-1"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Key Action
                  </Typography>
                  <Typography
                    className="col-span-1 md:col-span-2"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Description
                  </Typography>
                </div>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Brief Description
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      onChange={(e) => {
                        setMPPData((prev) => {
                          return {
                            ...prev,
                            mini_brief_description: e.target.value,
                          };
                        });
                      }}
                      value={MPPData.mini_brief_description}
                      rows={1}
                      style={FormStyles.inputBox}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-cols-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Key Objectives
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2  rounded-se-full block text-sm w-full outline-none"
                      onChange={(e) => {
                        setMPPData((prev) => {
                          return {
                            ...prev,
                            mini_key_objectives: e.target.value,
                          };
                        });
                      }}
                      value={MPPData.mini_key_objectives}
                      style={FormStyles.inputBox}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Estimated Budget
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setMPPData((prev) => {
                          return {
                            ...prev,
                            mini_estimated_budget: e.target.value,
                          };
                        });
                      }}
                      value={MPPData.mini_estimated_budget}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Estimated Timeline (weeks)
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setMPPData((prev) => {
                          return {
                            ...prev,
                            mini_estimated_timeline: e.target.value,
                          };
                        });
                      }}
                      value={MPPData.mini_estimated_timeline}
                      rows={1}
                    />
                  </div>
                </Box>
              </div>
            </>

            {/* Quick Project Initiation Document (PID) Section */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Quick Project Initiation Document (PID)
              </Typography>
              <div>
                <div className="grid grid-cols-3 gap-1 md:gap-4 my-2 pr-2 md:pr-4 pl-2 md:pl-10 py-2 AttributesHeader  ">
                  <Typography
                    className=""
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Scope
                  </Typography>
                  <Typography
                    className=""
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Milestones
                  </Typography>
                  <Typography
                    className=""
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Efforts (in hrs)
                  </Typography>
                </div>
                <div className="pt-0">
                  {PIDList.map((pid, index) => {
                    return (
                      <Box
                        className="grid grid-cols-3 relative items-center px-1 md:px-4 rounded-full bg-white mb-2"
                        sx={{
                          minHeight: "40px",
                          boxShadow: "6px 4px 30px 0px #0000000D",
                        }}
                        key={index}
                      >
                        <div
                          className="flex border-r-2 px-1 md:px-0"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className="px-1 md:px-4 mr-0 md:mr-2 block ml-0 md:ml-2 text-sm w-full outline-none"
                            required
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setPIDList((prevArr) => {
                                const result = [...prevArr];
                                result[index].scope = e.target.value;
                                return result;
                              });
                            }}
                            value={pid.scope}
                          />
                        </div>
                        <div
                          className="flex border-r-2 px-1 md:px-0"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className="px-1 md:px-4 mr-0 md:mr-2 block ml-0 md:ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setPIDList((prevArr) => {
                                const result = [...prevArr];
                                result[index].milestones = e.target.value;
                                return result;
                              });
                            }}
                            value={pid.milestones}
                          />
                        </div>
                        <div className="flex px-1 md:px-0">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className="px-1 md:px-4 mr-0 md:mr-2 block ml-0 md:ml-2 text-sm outline-none w-full"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setPIDList((prevArr) => {
                                const result = [...prevArr];
                                result[index].efforts = e.target.value;
                                return result;
                              });
                            }}
                            value={pid.efforts}
                          />
                        </div>
                        <button
                          className=" absolute right-1 md:right-3 top-2"
                          onClick={() => {
                            if (PIDList.length === 1) {
                              return false;
                            }
                            setPIDList((prevArr) => {
                              const result = [...prevArr];
                              result.splice(index, 1);
                              return result;
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            sx={FormStyles.DeleteButtonIcon}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>
                <Button
                  type="button"
                  sx={FormStyles.variantAddButton}
                  variant="contained"
                  onClick={() => {
                    handleAddPID();
                  }}
                >
                  <AddIcon
                    sx={{ color: "#221F4B", fontWeight: 700, fontSize: "30px" }}
                  />
                </Button>
                <div>
                  <Box
                    className="grid grid-cols-6 md:grid-cols-3 gap-2 md:gap-4 my-2 pr-4   rounded-full items-center bg-white"
                    sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                  >
                    <Typography
                      className="pl-3 md:pl-10 py-2 rounded-l-full col-span-3 md:col-span-1"
                      sx={FormStyles.LeftSideLabel}
                    >
                      Key Stakeholders
                    </Typography>
                    <div className="col-span-3 md:col-span-2 mr-4">
                      <input
                        type="text"
                        id={"input-label"}
                        className="pl-2 pr-4 block ml-2 text-sm w-full outline-none"
                        style={FormStyles.inputBox}
                        placeholder={""}
                        onInput={(e) => {
                          setkey_stakeholders(e.target.value);
                        }}
                        value={key_stakeholders}
                      />
                    </div>
                  </Box>
                </div>
              </div>
            </>

            {/* Simplified Business Case */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Simplified Business Case
              </Typography>
              <div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 my-2 pr-4 pl-4 md:pl-10 py-2 AttributesHeader  ">
                  <Typography
                    className="col-span-1"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Key Action
                  </Typography>
                  <Typography
                    className="col-span-1 md:col-span-2"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Description
                  </Typography>
                </div>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Problem/ Opportunity
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return {
                            ...prev,
                            problem_opportunity: e.target.value,
                          };
                        });
                      }}
                      value={SBCData.problem_opportunity}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Cost Benefit
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return { ...prev, const_beneifit: e.target.value };
                        });
                      }}
                      value={SBCData.const_beneifit}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Estimated Costs
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return { ...prev, estimeted_consts: e.target.value };
                        });
                      }}
                      value={SBCData.estimeted_consts}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Estimated Benefits
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return {
                            ...prev,
                            estimeted_beneifits: e.target.value,
                          };
                        });
                      }}
                      value={SBCData.estimeted_beneifits}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>ROI</Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <textarea
                      type="text"
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return { ...prev, roi: e.target.value };
                        });
                      }}
                      value={SBCData.roi}
                      rows={1}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 pl-4 md:pl-10 relative rounded-full bg-white mb-2"
                  sx={{
                    minHeight: "40px",
                    boxShadow: "6px 4px 30px 0px #0000000D",
                  }}
                >
                  <div className="flex border-r-2 my-2 col-span-1 items-center">
                    <Typography sx={FormStyles.FeatureLabels}>
                      Estimated ROI %
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2 mr-2 md:mr-4">
                    <input
                      type="number"
                      maxLength={3}
                      id={"input-label"}
                      className="py-2 rounded-se-full block text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setSBCData((prev) => {
                          return {
                            ...prev,
                            estimated_roi_percentage: e.target.value,
                          };
                        });
                      }}
                      value={SBCData.estimated_roi_percentage}
                      rows={1}
                    />
                  </div>
                </Box>
              </div>
            </>

            {/* Basic KPI Dashboard Section */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Basic KPI Dashboard
              </Typography>
              <div className="hidden md:block">
                <div className="grid grid-cols-4 gap-4 my-2 pr-4 pl-10 py-2 AttributesHeader  ">
                  <div className="">Scope</div>
                  <div className="">Current Status</div>
                  <div className="">Target</div>
                  <div className="">Actual</div>
                </div>
                <div className="pt-0">
                  {KPIList.map((kpi, index) => {
                    return (
                      <Box
                        className="grid grid-cols-4 relative items-center px-4 rounded-full bg-white mb-2"
                        sx={{
                          minHeight: "40px",
                          boxShadow: "6px 4px 30px 0px #0000000D",
                        }}
                        key={index}
                      >
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].kpi_scope = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.kpi_scope}
                          />
                        </div>
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].current_status = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.current_status}
                          />
                        </div>
                        <div
                          className="flex border-r-2"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 block ml-2 mr-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].target = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.target}
                          />
                        </div>
                        <div className="flex">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 block ml-2 text-sm outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].actual = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.actual}
                          />
                        </div>
                        <button
                          className=" absolute right-3 top-2"
                          onClick={() => {
                            if (KPIList.length === 1) {
                              return false;
                            }
                            setKPIList((prevArr) => {
                              const result = [...prevArr];
                              result.splice(index, 1);
                              return result;
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            sx={FormStyles.DeleteButtonIcon}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>

                <div></div>
              </div>
              <div className=" block md:hidden py-2">
                {KPIList.map((kpi, index) => {
                  return (
                    <div className="mb-2">
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 pr-4 items-center bg-white rounded-t-2xl"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-2 md:pl-10 py-1 pt-2 col-span-2 rounded-tl-2xl"
                          sx={FormStyles.LeftSideLabel}
                        >
                          Scope
                        </Typography>
                        <div className="col-span-3 ">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].scope = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.scope}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 pr-4 items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-2 md:pl-10 py-1 col-span-2"
                          sx={FormStyles.LeftSideLabel}
                        >
                          Current Status
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].current_status = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.current_status}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 pr-4 items-center bg-white"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-2 md:pl-10 py-1 col-span-2"
                          sx={FormStyles.LeftSideLabel}
                        >
                          Target
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].target = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.target}
                          />
                        </div>
                      </Box>
                      <Box
                        className="grid grid-cols-5 gap-1 md:gap-4 pr-4 items-center bg-white rounded-b-2xl"
                        sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                      >
                        <Typography
                          className="pl-2 md:pl-10 py-1 pb-2 col-span-2 rounded-bl-2xl"
                          sx={FormStyles.LeftSideLabel}
                        >
                          Actual
                        </Typography>
                        <div className="col-span-3">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-4 mr-2 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setKPIList((prevArr) => {
                                const result = [...prevArr];
                                result[index].actual = e.target.value;
                                return result;
                              });
                            }}
                            value={kpi.actual}
                          />
                        </div>
                      </Box>
                    </div>
                  );
                })}
              </div>
              <Button
                type="button"
                sx={FormStyles.variantAddButton}
                variant="contained"
                onClick={() => {
                  handleAddKPI();
                }}
              >
                <AddIcon
                  sx={{ color: "#221F4B", fontWeight: 700, fontSize: "30px" }}
                />
              </Button>
            </>

            {/* Stage Review Lite Section */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Stage Review Lite
              </Typography>
              <div>
                <div className="grid grid-cols-7 md:grid-cols-3 gap-2 md:gap-4 my-2 pr-4 pl-4 md:pl-10 py-2 AttributesHeader  ">
                  <Typography
                    className="col-span-3 md:col-span-1"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Objectives Met
                  </Typography>
                  <Typography
                    className="col-span-4 md:col-span-2"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Next Steps
                  </Typography>
                </div>
                <div className="pt-0">
                  {SRLList.map((srl, index) => {
                    return (
                      <Box
                        className="grid grid-cols-7 md:grid-cols-3 relative items-center px-4 rounded-full bg-white mb-2"
                        sx={{
                          minHeight: "40px",
                          boxShadow: "6px 4px 30px 0px #0000000D",
                        }}
                        key={index}
                      >
                        <div
                          className="flex border-r-2 col-span-3 md:col-span-1"
                          style={FormStyles.inputsBetweenBorder}
                        >
                          <input
                            type="text"
                            id={"input-label" + index}
                            className="px-1 md:px-4 block ml-1 md:ml-2 mr-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setSRLList((prevArr) => {
                                const result = [...prevArr];
                                result[index].objectives_met = e.target.value;
                                return result;
                              });
                            }}
                            value={srl.objectives_met}
                          />
                        </div>
                        <div className="flex col-span-4 md:col-span-2 mr-6">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className="px-1 md:px-4 w-full block ml-2 text-sm outline-none"
                            style={FormStyles.inputBox}
                            onInput={(e) => {
                              setSRLList((prevArr) => {
                                const result = [...prevArr];
                                result[index].next_steps = e.target.value;
                                return result;
                              });
                            }}
                            value={srl.next_steps}
                          />
                        </div>
                        <button
                          className=" absolute right-3 top-2"
                          onClick={() => {
                            if (SRLList.length === 1) {
                              return false;
                            }
                            setSRLList((prevArr) => {
                              const result = [...prevArr];
                              result.splice(index, 1);
                              return result;
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            sx={FormStyles.DeleteButtonIcon}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>
                <Button
                  type="button"
                  sx={FormStyles.variantAddButton}
                  variant="contained"
                  onClick={() => {
                    handleAddSRL();
                  }}
                >
                  <AddIcon
                    sx={{ color: "#221F4B", fontWeight: 700, fontSize: "30px" }}
                  />
                </Button>
                <div></div>
              </div>
            </>

            {/* Project Closure Lite Section */}
            <>
              <Typography sx={FormStyles.SectionLabel}>
                Project Closure Lite
              </Typography>
              <div>
                <div className="grid grid-cols-1 gap-2 md:gap-4 my-2 pr-4 pl-4 md:pl-10 py-2 AttributesHeader  ">
                  <Typography
                    className="col-span-4 md:grid-cols-2"
                    sx={FormStyles.SectionHeaderLabelText}
                  >
                    Deliverables
                  </Typography>
                </div>
                <div className="pt-0">
                  {deliverablesList.map((deliverable, index) => {
                    return (
                      <Box
                        className="grid grid-cols-1 relative items-center px-2 md:px-4 rounded-full bg-white mb-2 "
                        sx={{
                          minHeight: "40px",
                          boxShadow: "6px 4px 30px 0px #0000000D",
                        }}
                        key={index}
                      >
                        <div className="flex mr-6">
                          <input
                            type="text"
                            id={"input-label" + index}
                            className=" px-1 md:px-4 block ml-2 text-sm w-full outline-none"
                            style={FormStyles.inputBox}
                            required
                            placeholder={""}
                            onInput={(e) => {
                              setDeliverablesList((prevArr) => {
                                const result = [...prevArr];
                                result[index].deliverable = e.target.value;
                                return result;
                              });
                            }}
                            value={deliverable.deliverable}
                          />
                        </div>
                        <button
                          className=" absolute right-3 top-2"
                          onClick={() => {
                            if (deliverablesList.length === 1) {
                              return false;
                            }
                            setDeliverablesList((prevArr) => {
                              const result = [...prevArr];
                              result.splice(index, 1);
                              return result;
                            });
                          }}
                        >
                          <DeleteOutlinedIcon
                            sx={FormStyles.DeleteButtonIcon}
                          />
                        </button>
                      </Box>
                    );
                  })}
                </div>
                <Button
                  type="button"
                  sx={FormStyles.variantAddButton}
                  variant="contained"
                  onClick={() => {
                    handleAddDeliverables();
                  }}
                >
                  <AddIcon
                    sx={{ color: "#221F4B", fontWeight: 700, fontSize: "30px" }}
                  />
                </Button>
                <div className="grid md:grid-cols-2 md:gap-4 my-1 md:my-2">
                  <Box
                    className="grid grid-cols-2 gap-1 md:gap-4 my-1 md:my-2 pr-4 rounded-full items-center bg-white"
                    sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                  >
                    <Typography
                      className="pl-4 md:pl-10 py-2 rounded-l-full"
                      sx={FormStyles.LeftSideLabel}
                    >
                      Budget/Timeline
                    </Typography>
                    <div className="">
                      <input
                        type="text"
                        id={"input-label"}
                        className="px-1 md:px-4 block mr-2 text-sm w-full outline-none"
                        style={FormStyles.inputBox}
                        onChange={(e) => {
                          setPCLData((prev) => {
                            return { ...prev, budget_timeline: e.target.value };
                          });
                        }}
                        value={PCLData.budget_timeline}
                      />
                    </div>
                  </Box>
                  <Box
                    className="grid grid-cols-2 gap-1 md:gap-4 md:my-2 pr-4 rounded-l-full md:rounded-full items-center"
                    sx={{}}
                  >
                    <Typography
                      className="pl-4 md:pl-10 py-2 rounded-l-full md:rounded-full"
                      sx={FormStyles.LeftSideLabel}
                    >
                      Were they met?
                    </Typography>
                    <div className="">
                      <RadioGroup row value={PCLData.were_they_met}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={PCLData.were_they_met === "yes"}
                              onChange={handleWereTheyMetChange}
                              sx={FormStyles.wereTheyMet}
                              value="yes"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "no" }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={PCLData.were_they_met === "no"}
                              onChange={handleWereTheyMetChange}
                              sx={FormStyles.wereTheyMet}
                              value="no"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "no" }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </Box>
                </div>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 my-1 md:my-2 pr-4   rounded-full items-center bg-white"
                  sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                >
                  <Typography
                    className="pl-4 md:pl-10 py-2 rounded-l-full"
                    sx={FormStyles.LeftSideLabel}
                  >
                    Remarks
                  </Typography>
                  <div className="md:col-span-2">
                    <input
                      type="text"
                      id={"input-label"}
                      className="px-1 md:px-4 block mr-2 text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setPCLData((prev) => {
                          return { ...prev, remarks: e.target.value };
                        });
                      }}
                      value={PCLData.remarks}
                    />
                  </div>
                </Box>
                <Box
                  className="grid grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 md:my-2 pr-4   rounded-full items-center bg-white"
                  sx={{ boxShadow: "6px 4px 30px 0px #0000000D" }}
                >
                  <Typography
                    className="pl-3 md:pl-10 py-2 rounded-l-full"
                    sx={FormStyles.LeftSideLabel}
                  >
                    One lesson learned
                  </Typography>
                  <div className="md:col-span-2">
                    <input
                      type="text"
                      id={"input-label"}
                      className="px-1 md:px-4 block mr-2 text-sm w-full outline-none"
                      style={FormStyles.inputBox}
                      onChange={(e) => {
                        setPCLData((prev) => {
                          return {
                            ...prev,
                            one_lesson_learned: e.target.value,
                          };
                        });
                      }}
                      value={PCLData.one_lesson_learned}
                    />
                  </div>
                </Box>
                <div className="hidden md:block">
                  <Box className="flex w-full justify-end flex-row buttonGroup py-3">
                    <Box className="flex flex-col ml-2">
                      <Box sx={{ position: "relative" }}>
                        <Button
                          variant="contained"
                          sx={FormStyles.DraftSaveBTN}
                          disabled={isSubmitLoading}
                          onClick={() => {
                            handleDraftSave();
                          }}
                        >
                          Draft Save
                        </Button>
                        {isSubmitLoading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "#221F4B",
                              position: "absolute",
                              top: "50%",
                              left: "43%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                      <RadioGroup>
                        <FormControlLabel
                          value="female"
                          sx={FormStyles.radioLabel}
                          control={
                            <Radio
                              sx={FormStyles.radioBTN}
                              checked={finalSubmit === 1}
                              onClick={() => {
                                setFinalSubmit((prev) => {
                                  return prev === 0 ? 1 : 1;
                                });
                              }}
                            />
                          }
                          label="Final Submit"
                        />
                      </RadioGroup>
                    </Box>
                    <Box className="ml-2">
                      <Button
                        variant="outlined"
                        sx={FormStyles.CancelBTN}
                        onClick={() => {
                          navigate("/dashboard/fpm");
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </div>
                {snackBarShow ? (
                  <SnackBarComponent
                    open={snackBarShow}
                    message={snackBarMessage}
                  />
                ) : null}
              </div>
            </>
          </div>
          <div className="block md:hidden">
            <Box className="flex w-full justify-center flex-row buttonGroup py-1">
              <Box className="flex flex-col ml-2">
                <Box sx={{ position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={FormStyles.DraftSaveBTN}
                    disabled={isSubmitLoading}
                    onClick={() => {
                      handleDraftSave();
                    }}
                  >
                    Draft Save
                  </Button>
                  {isSubmitLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#221F4B",
                        position: "absolute",
                        top: "50%",
                        left: "43%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
                <RadioGroup>
                  <FormControlLabel
                    value="female"
                    sx={FormStyles.radioLabel}
                    control={
                      <Radio
                        sx={FormStyles.radioBTN}
                        checked={finalSubmit === 1}
                        onClick={() => {
                          setFinalSubmit((prev) => {
                            return prev === 0 ? 1 : 1;
                          });
                        }}
                      />
                    }
                    label="Final Submit"
                  />
                </RadioGroup>
              </Box>
              <Box className="ml-2">
                <Button
                  variant="outlined"
                  sx={FormStyles.CancelBTN}
                  onClick={() => {
                    navigate("/dashboard/fpm");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </>
      )}
    </>
  );
}

export default FPMCreation;

const FormStyles = {
  portfolioLabels: {
    typography: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "22.23px",
      color: "#F3F9FF",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "17.29px",
      },
    },
  },
  portfolioSelect: {
    padding: "2px 10px 2px 8px",
    border: "2px solid #FF914E",
    borderRadius: "50px",
    backgroundColor: "#ffdeca",
    typography: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22.23px",
      color: "#FF914E",
    },
    minHeight: "40px",
  },
  portfolioGridForm: {
    display: "flex",
    flexDirection: "column !important",
    [theme.breakpoints.down("md")]: {
      paddingTop: "1px",
    },
  },
  portfolioInput: {
    backgroundColor: "#ffdeca",
    border: "1px solid #FF914E",
    padding: "3px 10px 3px 5px",
    borderRadius: 10,
    minHeight: "40px",
    my: 1,
    input: {
      borderBottom: "1px solid #FF914E",
      color: "#FF914E",
      margin: "0px 10px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#FF914E",
    },
    [theme.breakpoints.down("md")]: {
      my: 0,
    },
  },
  portfolioVersionLabel: {
    background: "#FBC7A4",
    color: "#801F52",
    fontSize: "14px",
    fontWeight: 600,
  },
  selectStartIcon: {
    borderRadius: 50,
    color: "#fff",
    height: "30px",
    width: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  InputStartIcon: {
    background: "#FF914E",
    borderRadius: 50,
    color: "#fff",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  varientHeaderText: {
    typography: {
      color: "#221F4B",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "17.29px",
    },
  },
  inputsBetweenBorder: {
    borderRight: "2px solid #94B1CB",
  },
  variantAddButton: {
    background: "#fff",
    borderRadius: "40px",
    height: "40px",
    width: "40px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
      transform: "scale(1.1)",
    },
  },
  SectionLabel: {
    mt: 6,
    marginBottom: "5px",
    typography: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "27.17px",
      color: "#FF914E",
    },
    [theme.breakpoints.down("md")]: {
      mt: 3,
      typography: {
        fontSize: "18px",
        lineHeight: "22.23px",
      },
    },
  },
  SectionHeaderLabelText: {
    typography: {
      fontSize: "14px",
    },
  },
  FeatureLabels: {
    typography: {
      color: "#221F4B",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "17.29px",
    },
  },
  DeleteButtonIcon: {
    typography: {
      fontSize: "20px",
      color: "#94B1CB",
    },
  },
  LeftSideLabel: {
    backgroundColor: "#801F52",
    color: "#ffffff",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  DraftSaveBTN: {
    backgroundColor: "#801F52",
    borderRadius: "10px",
    py: 1,
    typography: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "19.76px",
      textTransform: "uppercase",
    },
    "&:hover": {
      backgroundColor: "#801F52",
    },
  },
  CancelBTN: {
    px: 4,
    py: 1,
    borderRadius: "10px",
    border: "1px solid #801F52",
    color: "#801F52",
    typography: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "19.76px",
      textTransform: "uppercase",
    },
    "&:hover": {
      border: "1px solid #801F52",
      color: "#801F52",
    },
  },
  radioBTN: {
    color: "#FF914E",
    "&.Mui-checked": {
      color: "#FF914E",
    },
  },
  wereTheyMet: {
    color: "#221F4B",
    "&.Mui-checked": {
      color: "#221F4B",
    },
  },
  radioLabel: {
    typography: {
      color: "#FF914E",
    },
  },
  inputBox: {
    borderBottom: "1px solid #94B1CB",
    color: "#221F4B",
  },
};
