import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isAuthenticated: false,
    token: null,
    userData: [],
    checkIn: {},
  },
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = [];
    },
    userData(state, action) {
      state.userData = action.payload
    },
    checkIn(state, action) {
      state.userData = action.payload
    }
  },
});

export const { login, logout, userData, checkIn } = sessionSlice.actions;
export default sessionSlice.reducer;