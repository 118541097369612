import React from "react";
import SearchTicket from "./Components/SearchTicket";
import FilterTickets from "./Components/FilterTickets";
import TicketSection from "./Components/TicketsSection";
import "./Tickets.css";

function TicketDashboard() {
  return (
    <div className="flex flex-col mx-2 md:mx-5">
      <SearchTicket />
      <FilterTickets />
      <TicketSection />
    </div>
  );
}

export default TicketDashboard;
