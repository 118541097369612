import searchSVG from "../../../Assets/search.svg";
import bellSVG from "../../../Assets/bell.svg";
import PlusSVG from "../../../Assets/plus.svg";
import messageSVG from "../../../Assets/Message.svg";
import EditSVG from "../../../Assets/EditIcon.svg";
import DeleteSVG from "../../../Assets/DeleteIcon.svg";
import deleteWarningIcon from "../../../Assets/DeleteDialogIcon.svg";
import "./LookupTypes.css";
import { useEffect, useState } from "react";
import { DatabaseService } from "../../../Services/DatabaseService";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/material";
import FormatedTableValue from "../../shared/common/FormatedTableValue";

const theme = createTheme();

const LookupType = () => {
  const [loading, setLoading] = useState(true);
  const [LookupTypeData, setLookupTypeData] = useState([]);
  const [tempDeleteLookupId, setTempDeleteLookupId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [searchInputValue, setSearchInputValue] = useState("");
  const [timer, setTimer] = useState(null);

  const data = useLoaderData();

  const navigate = useNavigate();

  useEffect(() => {
    getLookupTypeData();
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
  }, [screenSize]);

  const inputChanged = (e) => {
    setSearchInputValue(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      console.log("search Api Calling");
      getLookupTypeData(e.target.value);
    }, 500);

    setTimer(newTimer);
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const getLookupTypeData = async (value) => {
    let response = await getLookupTypes(value);
    if (response.code === 200) {
      setLoading(false);
      setLookupTypeData(response.data);
    } else {
      setLoading(false);
    }
  };

  const handleEditLookup = (id) => {
    if (id) {
      navigate("/dashboard/lookup_type/" + id);
    }
  };

  const handleDeleteLookup = async (id) => {
    setTempDeleteLookupId(id);
    setShowModal(true);
  };

  const ConfirmDeleteLookup = async () => {
    if (tempDeleteLookupId) {
      let response = await DatabaseService(
        "DELETE",
        "lookup/type",
        null,
        tempDeleteLookupId
      );
      console.log("delete_response--------------------------->", response);
      if (response.code == 200) {
        setShowModal(false);
        getLookupTypeData();
        // alert("Lookup Type Deleted.");
      } else {
        alert("Lookup Type Deletion Failed, Please Trt After Sometime.");
      }
    }
  };

  return (
    <div className="LookUpSection">
      {/* chat header */}
      <div className="chatHeader my-1 md:my-3">
        <div className="w-full rounded-full flex px-0 md:px-3">
          <div className=" flex justify-center items-center flex-grow ml-11 md:ml-0 mr-2">
            <button
              disabled
              type="reset"
              className="flex w-10 items-center justify-center leftSearchIcon z-10"
            >
              <img src={searchSVG} />
            </button>
            <input
              type="text"
              className="w-full  -ml-4 h-8 pl-6 text-base font-semibold outline-0 rounded-r-3xl searchInput"
              onChange={inputChanged}
              value={searchInputValue}
              placeholder=""
              id=""
            />
            {/* <input type="button" value="Buscar" className="bg-blue-500 p-2 rounded-tr-lg rounded-br-lg text-white font-semibold hover:bg-blue-800 transition-colors"/> */}
          </div>
          <div className="flex-grow-0">
            <Link to={"/dashboard/lookup_type/add"} className="notificationBtn">
              <img src={PlusSVG} />
            </Link>
          </div>
          <div className="flex-grow-0 pl-2">
            <button className="notificationBtn" type="reset">
              <img src={messageSVG} />
            </button>
          </div>
          <div className="flex-grow-0 pl-2">
            <button className="notificationBtn" type="reset">
              <img src={bellSVG} />
            </button>
          </div>
        </div>
      </div>
      <div
        className="lookupData px-3 "
        style={{
          width:
            screenSize.width < 768 ? screenSize.width : screenSize.width - 400,
          height:
            screenSize.width < 768 ? screenSize.height - 60 : screenSize - 300,
        }}
      >
        <table
          className="w-full lookupTypeTable"
          border="1"
          frame="hsides"
          rules="rows"
        >
          <thead className="tableHeader">
            <th></th>
            <th>Lookup Type</th>
            <th>Attribute 1</th>
            <th>Attribute 2</th>
            <th>Attribute 3</th>
            <th>Attribute 4</th>
            <th>Attribute 5</th>
            <th>Attribute 6</th>
            <th>Attribute 7</th>
            <th>Attribute 8</th>
            <th>Attribute 9</th>
            <th>Attribute 10</th>
            <th> </th>
          </thead>
          <tbody className="tableBody">
            {loading ? (
              <tr>
                {" "}
                <td className="text-center" colSpan={2}>
                  {" "}
                  Loading...{" "}
                </td>{" "}
              </tr>
            ) : LookupTypeData && LookupTypeData.length ? (
              LookupTypeData.map((item, index) => {
                return (
                  <tr className="tableBodyRow py-2 shadow-sm" key={index}>
                    <td>
                      <input type="radio" />
                    </td>
                    <td>
                      <FormatedTableValue value={item.lookup_type_name} />
                    </td>
                    {item.attribute.map((attribute) => {
                      return (
                        <td>
                          <FormatedTableValue value={attribute.attribute_name} />
                        </td>
                      );
                    })}
                    <td className="actionCol">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          title="Edit"
                          onClick={() => {
                            handleEditLookup(item._id);
                          }}
                        >
                          {" "}
                          <img src={EditSVG} />{" "}
                        </button>
                        <div className="VeritacalSeprator"></div>
                        <button
                          type="button"
                          title="Delete"
                          onClick={() => {
                            handleDeleteLookup(item._id);
                          }}
                        >
                          {" "}
                          <img src={DeleteSVG} />{" "}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2} className="text-center pt-6 font-bold">
                  {" "}
                  No Data Found{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative px-10 md:px-28 py-6 flex-auto justify-center">
                  <img
                    className="mx-auto"
                    src={deleteWarningIcon}
                    alt="Delete Warning Icon"
                  />
                  <p className="my-4 text-blueGray-500 text-sm md:text-lg leading-relaxed text-center">
                    You're about to remove this item. <br /> Proceed with
                    confidence.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center pb-6 rounded-b">
                  <button
                    className="px-7 py-3 shadow hover:shadow-lg outline-none uppercase focus:outline-none mr-6 mb-1 ease-linear transition-all duration-150 submitButton"
                    type="button"
                    onClick={() => ConfirmDeleteLookup()}
                  >
                    Remove It
                  </button>
                  <button
                    className="px-10 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 keepItButton"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Keep It
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" ></div>
        </>
      ) : null}
    </div>
  );
};

export default LookupType;

export const getLookupTypes = async (searchText = "") => {
  let response;
  if (searchText) {
    response = await DatabaseService("GET", "lookup/type", null, searchText);
  } else {
    response = await DatabaseService("GET", "lookup/type");
  }
  return response;
};
