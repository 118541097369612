import React from "react";

function daywhite(props) {
  const {color="#F3F9FF"} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="-2.4 -2.4 28.8 28.8"
    >
      <g fill={color}>
        <path d="M3 5.675V3a1 1 0 00-2 0v4a2 2 0 002 2h4a1 1 0 100-2H4.522c.02-.025.04-.05.058-.078a8.991 8.991 0 11-1.515 6.083c-.061-.548-.508-.997-1.06-.997-.553 0-1.01.45-.959 1A11 11 0 103 5.675z"></path>
        <path d="M12 5a1 1 0 00-1 1v6.467s0 .26.127.457c.084.166.217.31.39.41l4.62 2.668a1 1 0 001-1.732L13 11.88V6a1 1 0 00-1-1z"></path>
      </g>
    </svg>
  );
}
  
export default daywhite;