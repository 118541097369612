import React from "react";

function arrows_white({color='#F3F9FF' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M5.341 5L4.167 6.175 7.984 10l-3.816 3.825L5.341 15l5-5-5-5z"
      ></path>
      <path
        fill={color}
        d="M10.833 5L9.658 6.175 13.475 10l-3.817 3.825L10.833 15l5-5-5-5z"
      ></path>
    </svg>
  );
}

export default arrows_white;