import React, { useState } from "react";
import PropTypes from "prop-types";
import favcyLogo from '../Assets/favcy_logo_orange.png';

const Image = ({ src, fallbackSrc=favcyLogo, ...props }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [errored, setErrored] = useState(false);

  const onError = () => {
    if (!errored) {
      setCurrentSrc(fallbackSrc);
      setErrored(true);
    }
  };

  return <img src={currentSrc} onError={onError} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
};

export default Image;
