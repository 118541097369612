import { url, version } from "../Constants/keys";

class RequestService {
    constructor() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }
    
    async fetchData(method, endpoint, bodyContent, id) {
        try {
        // Abort previous request if it exists
        this.controller.abort();
    
        // Create a new AbortController and signal for the current request
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    
        var myHeaders = new Headers();
        var token = await localStorage.getItem('userToken');
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("authorization", "Bearer "+ token );
        
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };
    
        if(method === 'POST' || method === 'post' || method === "PUT" || method === "put"){
            requestOptions.body = JSON.stringify(bodyContent);
        }
    
        let fullUrl = url + version + endpoint;
        if(id){
            fullUrl = fullUrl + '/'+ id;
        }
    
        const response = await fetch(fullUrl, { signal: this.signal, ...requestOptions });
        const data = await response.json();
    
        return data;
        } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Request canceled');
        } else {
            // Handle other errors
            console.error('Request error', error);
        }
        }
    }
    }
    
export default new RequestService();
      