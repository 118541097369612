import { useDispatch, useSelector } from "react-redux";
import { utils } from "../../../Common/Utills";
import { DatabaseService } from "../../../Services/DatabaseService";
import { useEffect, useState } from "react";
import { checkIn, login, logout, userData } from "../../../Redux/sessionSlice";
import { useNavigate } from "react-router-dom";
import { Box, LinearProgress, Typography } from "@mui/material";

const Protected = (props) =>{
    let { Component } = props;
    const dispatch = useDispatch();
    const isProfileData = useSelector((state) => state?.session?.userData);
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [navigateBack, setNavigateBack] = useState(false);  
    const [progress, setProgress] = useState(10);

    const [userCheckInData, setUserCheckInData] = useState();

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);

    useEffect(()=>{
        checkLoggedIn();
        console.log("calllllling");
      },[]);
  
      const checkLoggedIn= async () =>{
        setIsLoading(true);
        let checkLoggedIn = await utils.checkLoggedIn().then((response)=>{return response});
        console.log("checkedLoggeIn--------------------->", checkLoggedIn);
        let isCheckedOut = await DatabaseService("GET", "attendance/logincheck");
        console.log("isCheckedOut---------------->",isCheckedOut);
        if(isCheckedOut.code === 200){
          let responseData = isCheckedOut.data;
          console.log('responseData---------->',responseData)
          if(responseData ){
            setUserCheckInData(responseData);
            dispatch(checkIn(responseData));
            if( responseData.check_out_date && responseData.check_out_date !== ""){
              console.log('checkout_date');
              setNavigateBack(true);
              navigate('/');
              window.location.reload();
              setIsLoading(false);
              return
            }
          }else{
            setNavigateBack(true);
            setIsLoading(false);
            navigate('/');
            window.location.reload();
            return
          }
        }
        if(checkLoggedIn){
            dispatch(login(checkLoggedIn));
            if(!isProfileData._id){
              let getProfile = await DatabaseService('GET', '/users/single');
              setProgress(100);
              setIsLoading(false);
              dispatch(userData(getProfile.data))
              setProfileData(getProfile.data);
            }else{
              dispatch(userData(isProfileData))
              setProfileData(isProfileData);
              setProgress(100);
            }
            setIsLoading(false);
        }else{
          setNavigateBack(true);
          setIsLoading(false);
          await utils.clearAllData();
          dispatch(logout(''));
          navigate('/');
        }
      }



    return ( 
        <div>
              {
                isLoading ? 
                <div className="h-96 flex justify-center w-full items-center flex-col" >
                  <Typography >Loading...</Typography>
                  <Box sx={{width: '50%' }}>
                    <LinearProgressWithLabel value={progress} />
                  </Box>
                </div>
                :
                <Component userProfileData={profileData} checkInData={userCheckInData} />
              }
        </div>
     );
}

export default Protected;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}