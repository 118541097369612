import "./App.css";
import Main from "./Components/Main";
import { Provider } from "react-redux";
import configureStore from "./Redux/configureStore";
import { ThemeProvider, createTheme } from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={configureStore}>
        <Main />
      </Provider>
    </ThemeProvider>
  );
}

export default App;

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: "none",
    },
  },
});
