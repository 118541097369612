import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  Paper,
  Typography,
} from "@mui/material";
import {
  createTheme,
  experimentalStyled as styled,
} from "@mui/material/styles";
import React from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Image from "../../../../Common/FastImage";
import DemoImage from "../../../../Assets/dummyImages/Ellipse_42.png";
import HourGlassSvg from "../../../../Assets/hourGlass.svg";
import CalenderInfoSvg from "../../../../Assets/calenderInfo.svg";
import CommentIconSVG from "../../../../Assets/commentIcon.svg";
import AttachIconSvg from "../../../../Assets/attachIcon.svg";
const theme = createTheme();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TicketCard({ obj, index }) {
  const portfolio = () => {
    return (
      <div className="flex gap-2 items-center mb-2">
        <div className="portfolioImage">
          <Image
            src={"../../../../logo.svg"}
            alt="Portfolio Image"
            className="p-1"
          />
        </div>
        <div>
          <Typography
            sx={{
              typography: {
                fontSize: 14,
                [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
                fontWeight: 500,
                color: "#FF914E",
              },
            }}
          >
            Paytamasha
          </Typography>
        </div>
      </div>
    );
  };

  const CreatedBy = () => {
    return (
      <div className="flex gap-2 items-center mb-2">
        <div className="">
          <Avatar src={DemoImage} sx={{ width: 30, height: 30 }} />
        </div>
        <div>
          <Typography
            sx={{
              typography: {
                fontSize: 12,
                [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
                fontWeight: 500,
                color: "#221F4B",
              },
            }}
          >
            Ashish Ajmani
          </Typography>
          <Typography
            sx={{
              typography: {
                fontSize: 10,
                [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
                fontWeight: 500,
                color: "#94B1CB",
                textAlign: "left",
              },
            }}
          >
            Oct 22’2023
          </Typography>
        </div>
      </div>
    );
  };

  const KeyValue = (key = "key", value = "value") => {
    return (
      <div className="flex">
        <Typography
          sx={{
            typography: {
              fontSize: 12,
              [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
              fontWeight: 500,
              color: "#221F4B",
            },
          }}
        >
          {key}:{" "}
        </Typography>
        <Typography
          sx={{
            typography: {
              fontSize: 12,
              [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
              fontWeight: 500,
              color: "#0485E1",
            },
          }}
        >
          &nbsp; {value}
        </Typography>
      </div>
    );
  };

  const ticketDetails = (
    label = "Design of IntraHub- Mobile View",
    description = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown..."
  ) => {
    return (
      <div className="ticketDetailsSection">
        <Typography
          sx={{
            typography: {
              fontSize: 12,
              [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
              fontWeight: 500,
              color: "#221F4B",
              textAlign: "left",
            },
          }}
        >
          {" "}
          {label}{" "}
        </Typography>
        <Divider sx={{ bgcolor: "#73BDFF" }}></Divider>
        <Typography
          sx={{
            typography: {
              fontSize: 12,
              [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
              fontWeight: 500,
              color: "#221F4B",
              textAlign: "left",
            },
          }}
        >
          {" "}
          {description}{" "}
        </Typography>
      </div>
    );
  };

  const CreateData_Time = (time = '6 Hrs', date = '23/08/2023') =>{
    return (<div className="flex mt-1 gap-2 mb-4" >
      <Typography
      sx={{
        typography: {
          fontSize: 12,
          [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
          fontWeight: 500,
          color: "#221F4B",
          display: 'flex',
          marginRight: 10
        },
      }}>
        <img src={HourGlassSvg} /> 
        {time}
      </Typography>
      <Typography
      sx={{
        typography: {
          fontSize: 12,
          [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
          fontWeight: 500,
          color: "#221F4B",
          display: 'flex'
        },
      }}>
        <img src={CalenderInfoSvg} /> 
        {date}
      </Typography>
    </div>)
  }

  const commentSection = ( ) => {
    return (
      <div className="flex justify-end">
        <Button sx={{minWidth: 10, paddingY: 0, paddingX: '5px'}} >
            <img src={CommentIconSVG} alt="Comment Icon" />          
        </Button>
        <Button sx={{minWidth: 10, paddingY: 0, paddingX: '5px'}} >
            <img src={AttachIconSvg} alt="Attach Icon" />          
        </Button>
      </div>
    )
  }

  const progressEdit = (status="In progress", color="#0485E1") => {
    return (
      <div className="absolute right-0 top-4 gap-1 flex items-center">
        <Button sx={{minWidth: 10, paddingY: 0, paddingX: '5px', color : '#94B1CB'}} >
          <VisibilityOutlinedIcon />
        </Button>
        <Typography sx={{
        typography: {
          fontSize: 12,
          [theme.breakpoints.down("md")]: { fontSize: "0.7rem" },
          fontWeight: 500,
          color: "#FFFFFF",
          display: 'flex',
          padding: '3px 10px',
          borderTopLeftRadius: '53.86px',
          borderBottomLeftRadius: '53.86px'
        },
        bgcolor: color,
      }} > {status} </Typography>
      </div>
    )
  }

  return (
    <>
      <Grid
        item
        xs={2}
        sm={4}
        md={3}
        key={index}
        sx={{ paddingRight: 2, paddingBottom: 2 }}
      >
        <Item
          sx={{ boxShadow: "6px 4px 40px 0px #0E50831A", borderRadius: "8px", position:'relative' }}
        >
          {portfolio()}
          {CreatedBy()}
          {KeyValue("Assignne", "Shivangi Singh")}
          {ticketDetails()}
          {CreateData_Time()}
          {KeyValue("Approved by", "Ragini")}
          {KeyValue("Allocated by", "Ashish Ajmani")}
          {commentSection()}
          {progressEdit()}
        </Item>
      </Grid>
    </>
  );
}

export default TicketCard;
