import * as React from "react"
function ticket_board (props){
    const {color="#F3F9FF"} = props;
    return(
        <svg width={16} height={16} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.91667 12.8334H11.0833C11.7267 12.8334 12.25 12.3102 12.25 11.6667V2.91675C12.25 2.27333 11.7267 1.75008 11.0833 1.75008H9.91667C9.91667 1.59537 9.85521 1.447 9.74581 1.3376C9.63642 1.22821 9.48804 1.16675 9.33333 1.16675H4.66667C4.51196 1.16675 4.36358 1.22821 4.25419 1.3376C4.14479 1.447 4.08333 1.59537 4.08333 1.75008H2.91667C2.27325 1.75008 1.75 2.27333 1.75 2.91675V11.6667C1.75 12.3102 2.27325 12.8334 2.91667 12.8334ZM2.91667 2.91675H4.08333V4.08342H9.91667V2.91675H11.0833V11.6667H2.91667V2.91675Z" fill={color}/>
        <path d="M6.41762 7.92523L5.37171 6.87931L4.54688 7.70415L6.41762 9.5749L9.45504 6.53748L8.63021 5.71265L6.41762 7.92523Z" fill={color}/>
        </svg>
    )
}
export default ticket_board