import React, { useEffect, useState } from "react";
import HeaderSearch from "../../shared/HeaderSearch";
import AvailableLeaveSection from "./Components/AvailableLeaveSection";
import LeavesSection from "./Components/LeavesSection";
import { Box, CircularProgress, Dialog, DialogContent, Divider, Typography, createTheme } from "@mui/material";
import HolidaysSection from "./Components/HolidaysSection";
import LeaveForm from "./Components/LeaveForm";
import { DatabaseService } from "../../../Services/DatabaseService";
import SnackBarComponent from "../../shared/common/SnackBar";
import dayjs from "dayjs";

const theme = createTheme();

const LeaveTracker = ({ userData = {} }) => {
  const [applyLeaveFormShow, setApplyLeaveFormShow] = useState(false);
  const [currentSection, setCurrentSection] = useState("leaves");
  const [fetchedLeaves, setFetchedLeaves] = useState([]);
  const [fetchedPerHolidays, setFetchedPerHolidays] = useState([]);
  const [fetchedOptHolidays, setFetchedOptHolidays] = useState([]);
  const [fetchedManagerData, setFetchedManagerData] = useState([]);
  const [fetchedMyAppliedLeaves, setFectchedMyAppliedLeaves] = useState([]);
  const [fetchedMyAppliedPagination, setFectchedMyAppliedPagination] = useState();
  const [fetchedAppliedPagination, setFectchedAppliedPagination] = useState();
  const [fetchedAppliedLeaves, setFectchedAppliedLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [selectedFormData, setSelectedFormData] = useState();
  const [leaveUpdates, setLeaveUpdates] = useState();
  const [showHolidays, setShowHolidays] = useState(false);
  const [showLeaves, setShowLeaves] = useState(false);
  const [leavesLoading, setLeavesLoading] = useState(true);

  useEffect(() => {
    getLookupCodes();
    getMyLeavesApplied();
    getLeavesApplied();
    let Data = JSON.parse(localStorage.getItem("leavesUpdates")); //fetching data of leaves from localstorage
    if(Data){
      setLeaveUpdates(Data);
    }
  }, []);

  const getLookupCodes = async () => {
    if (isLoading === true) {
      return;
    }
    setIsLoading(true);
    let response = await DatabaseService("GET", "lookup/code");
    if (response && response.code === 200) {
      let completeResponseData = response.data;

      //filtered leaved data
      const leavesData = await completeResponseData
        .filter((item) => item.lookupType.lookup_type_name === "Leaves")
        .sort((a, b) => {
          const nameA = a.lookup_code_name.toLowerCase();
          const nameB = b.lookup_code_name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      const permanentHolidaysData = await completeResponseData.filter((item) =>
        item.lookupType.lookup_type_name.includes("Permanent")
      );
      const optionalHolidaysData = await completeResponseData.filter((item) =>
        item.lookupType.lookup_type_name.includes("Optional")
      );
      const LookupManager = await completeResponseData.find(
        (item) => item.lookup_code_name === userData.displayName
      );
      if (LookupManager) {
        let userResponse = await DatabaseService(
          "GET",
          "users/userDetailByEmail?email=" +
            LookupManager?.attribute.find(
              (obj) =>
                obj?.code_attribute_name.toLowerCase() === "manager email"
            )?.code_attribute_description
        );
        if (userResponse.code === 200) {
          setFetchedManagerData(userResponse.data);
        }else{
          setFetchedManagerData({
            displayName: "Vikrant Bahl",
            email: "vikrant.bahl@favcy.in",
            firstName: "Vikrant",
            googleId: "117779346472974669545",
            imageURL: "https://lh3.googleusercontent.com/a/ACg8ocIpZ7fbC8LewHsLpwAjHoiz50ZdtbgAx5sOBdT3MiRS=s96-c"
          });
        }
      }

      if (leavesData && leavesData.length) {
        let leaves = await leavesData.map((leave) => {
          return {
            id: leave._id,
            type: leave.lookup_code_name,
            description: leave.lookup_code_description,
            quantum: leave.attribute.find(
              (attr) =>
                attr.code_attribute_name.toString().trim().toLowerCase() ===
                "quantum"
            ).code_attribute_description,
          };
        });
        setFetchedLeaves(leaves);
        setFetchedPerHolidays(permanentHolidaysData);
        setFetchedOptHolidays(optionalHolidaysData);
      }
    } else {
      setIsLoading(false);
    }
  };

  const getMyLeavesApplied = async () => {
    let response = await DatabaseService(
      "GET",
      "attendance/applied-leave?type=single"
    );
    if (response && response.code === 200) {
      setFectchedMyAppliedLeaves(response.result);
      setFectchedMyAppliedPagination(response.pagination);
    }
    onApplyLeavePress(false);
    setSelectedFormData({});
  };

  const getLeavesAppliedWithPage = async (page) => {
    let response = await DatabaseService("GET", "attendance/applied-leave?page="+page);
    if (response && response.code === 200) {
      setFectchedAppliedLeaves(response.result);
      setFectchedAppliedPagination(response.pagination);
    }
    onApplyLeavePress(false);
    setSelectedFormData({});
    setIsLoading(false);
  };

  const getMyLeavesAppliedWithPage = async (page) => {
    let response = await DatabaseService(
      "GET",
      "attendance/applied-leave?type=single&page="+page
    );
    if (response && response.code === 200) {
      setFectchedMyAppliedLeaves(response.result);
      setFectchedMyAppliedPagination(response.pagination);
    }
    onApplyLeavePress(false);
    setSelectedFormData({});
    setIsLoading(false);
  };

  const getLeavesApplied = async () => {
    setIsLoading(true);
    let response = await DatabaseService("GET", "attendance/applied-leave");
    if (response && response.code === 200) {
      setFectchedAppliedLeaves(response.result);
      setFectchedAppliedPagination(response.pagination);
    }
    onApplyLeavePress(false);
    setIsLoading(false);
    setSelectedFormData({});
  };

  const onChangeSection = (section) => {
    setCurrentSection(section.target.value || "leaves");
  };

  const onApplyLeavePress = (value) => {
    setApplyLeaveFormShow(value);
    setSelectedFormData({});
  };

  const updateAppliedLeaves = () => {
    getLeavesApplied();
    getMyLeavesApplied();
    getUserAttendance();
    setIsSnackBarOpen(true);
    setSnackBarMessage("Leave Updated Successfully.");
    setTimeout(() => {
      setIsSnackBarOpen(false);
    }, 2000);
  };

  const getUserAttendance = async () => {
    let endpoint = "attendance/single-user";
      endpoint = endpoint + "?from_date="+ dayjs(Date.now()).add(1,"day").format("YYYY-MM-DD") +"&to_date="+dayjs(Date.now()).add(9,"day").format("YYYY-MM-DD")
    let attendResponse = await DatabaseService("GET", endpoint);
    if (attendResponse.code === 200) {
      setLeaveUpdates(attendResponse.leave);
      localStorage.setItem("leavesUpdates", JSON.stringify(attendResponse.leave));
    }
  };

  const handlePageChange = async (event, value, isAllShowing) => {
    console.log("onPagechange--->",value);
    console.log("onPagechange--isAllShowing->",isAllShowing);
    setIsLoading(true);
    if(isAllShowing){
      getLeavesAppliedWithPage(value);
    }else{
      getMyLeavesAppliedWithPage(value);
    }
  };

  const onEditButtonClick = async (item, isAllShowing) => {
    setIsManager(isAllShowing);
    setSelectedFormData(item);
    setApplyLeaveFormShow(true);
  };

  const handleNextPreviousClick = async (isNext) => {
    let selectedItemIndex = await fetchedAppliedLeaves.findIndex((item)=> item._id === selectedFormData._id);
    console.log("selectedItemIndex------>",selectedItemIndex);
    if(isNext === "next"){

    }else{

    }
  }

  const handleShowHolidays = async (value) => {
    setShowHolidays(value);
  }

  const handleShowLeave = async (value) => {
    setShowLeaves(value);
  }

  return (
    <Box sx={{ mx: 1, pb: 1, pt: 3, [theme.breakpoints.down("md")]: { pt: 2 } }}>
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          lineHeight: "27.17px",
          color: "#801F52",
          pb: 0.5,
          [theme.breakpoints.down("md")]: { textAlign: "center" },
        }}
      >
        My Leaves{" "}
      </Typography>
      <Divider sx={{ borderColor: "#FBC7A4" }} />
      <AvailableLeaveSection
        section={currentSection}
        handleSection={onChangeSection}
        applyLeaveFormShow={applyLeaveFormShow}
        handleApplyLeave={onApplyLeavePress}
        handleShowHolidays = {handleShowHolidays}
        handleShowLeaves = {handleShowLeave}
        leavesData={leaveUpdates}
      />
      {isLoading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : applyLeaveFormShow ? (
        <>
          <LeaveForm
            handleApplyLeave={onApplyLeavePress}
            allTypeLeaves={fetchedLeaves}
            managerData={fetchedManagerData}
            updateLeavesData={updateAppliedLeaves}
            selectedformData={selectedFormData}
            isManager={isManager}
            showAnotherData={handleNextPreviousClick}
            permanentHolidays={fetchedPerHolidays}
          />
        </>
      ) : currentSection === "leaves" ? (
        <LeavesSection
          allLeaves={fetchedAppliedLeaves}
          appliedData={fetchedMyAppliedLeaves}
          userPagination={fetchedMyAppliedPagination}
          allPagination={fetchedAppliedPagination}
          handleApplyLeave={onApplyLeavePress}
          handlePageChange={handlePageChange}
          handleEditClick={onEditButtonClick}
          handleShowHolidays = {handleShowHolidays}
          handleShowLeaves = {handleShowLeave}
          userData={userData}
        />
      ) : currentSection === "holidays" ? (
        <HolidaysSection
          permanentHolidays={fetchedPerHolidays}
          optionalHolidays={fetchedOptHolidays}
        />
      ) : (
        <LeavesSection allLeaves={fetchedLeaves} />
      )}
      {isSnackBarOpen ? <SnackBarComponent message={snackBarMessage} /> : null}
      {/* view holidays dialog box  */}
      <Dialog 
        open={showHolidays}
        maxWidth={"md"}
        onClose={()=>{setShowHolidays(false); setLeavesLoading(true);}}
        sx={{
          "& .MuiDialog-paper":{
            borderRadius: "16px",
            border: "2px solid #FF914E",
          },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent dividers={'paper'} sx={{height: "90vh", width: "650px"}}>
        {leavesLoading && <div className="loading">Loading...</div>}
        <iframe width={"100%"} height={"100%"} onLoad={()=>{setLeavesLoading(false)}} 
         style={{ display: leavesLoading ? 'none' : 'block' }}
         src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQQn-yIWT0Ll8AZP5bxGAl8LaLngXra-N8EwezO4wQFUIB7AsfgxzaAbMJGOL62He3uSXYE4fKfGnQQ/pubhtml?gid=0&single=true&headers=false&chrome=false"></iframe>
        </DialogContent>
      </Dialog>
      {/* view Leaves dialog box  */}
      <Dialog 
        open={showLeaves}
        maxWidth={"md"}
        onClose={()=>{setShowLeaves(false); setLeavesLoading(true);}}
        sx={{
          "& .MuiDialog-paper":{
            borderRadius: "16px",
            border: "2px solid #FF914E",
          },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent dividers={'paper'} sx={{height: "90vh", width: "58vw"}}>
        {leavesLoading && <div className="loading">Loading...</div>}
        <iframe width={"100%"} height={"100%"} onLoad={()=>{setLeavesLoading(false)}} 
        style={{ display: leavesLoading ? 'none' : 'block' }}
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQezw6rgTOM11CiIKz4uim509uHdf_ENLZGk4vNtv2I2oh74KdAnSqA9qwjQNt31Sa8mwNivlorOOu9/pubhtml?gid=0&single=true&headers=false&chrome=false"></iframe>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LeaveTracker;
